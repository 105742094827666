import React from 'react';
import styled, { css, keyframes } from 'styled-components';

interface TicketListSkeletonProps {
  twoWay: boolean;
}

const shimmer = keyframes`
    100% {
        transform: translateX(100%);
    }
`;

const background = css`
  position: relative;
  overflow: hidden;
  background-color: #eff2f6;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0,
      rgba(255, 255, 255, 0.2) 20%,
      rgba(255, 255, 255, 0.5) 60%,
      rgba(255, 255, 255, 0)
    );
    animation: ${shimmer} 1.5s infinite;
    content: '';
  }
`;

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const FilterWrapper = styled.div`
  width: 286px;
  margin-right: 26px;
  @media (max-width: 1024px) {
    display: none;
  }
`;

const FilterItem = styled.div`
  padding: 24px 0;
  border-bottom: 1px solid #dcdcdc;
`;

const FilterText = styled.div<{ width: number; marginBottom: number }>`
  height: 18px;
  border-radius: 4px;
  ${background}
  width: ${({ width }) => width}px;
  margin-bottom: ${({ marginBottom }) => marginBottom}px;
`;

const MiniText = styled(FilterText)`
  height: 14px;
  width: 47px;
`;

const Slider = styled.div`
  position: relative;
  height: 24px;
  padding: 12px 0;
  box-sizing: border-box;

  & > :first-child {
    position: absolute;
    top: 0;
    left: 0;
  }

  & > :last-child {
    position: absolute;
    top: 0;
    right: 0;
  }
`;

const Round = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 24px;
  ${background}
`;

const Row = styled.div`
  height: 1px;
  width: 100%;
  background: #dcdcdc;
`;

const List = styled.div`
  & > div {
    margin: 0 auto 40px;
    @media (max-width: 767px) {
      margin-bottom: 25px;
    }
  }

  & > :last-child {
    margin-bottom: 0;
  }

  padding: 0;
  margin: 0;
  position: relative;
  width: 858px;
  @media (max-width: 1188px) {
    width: 100%;
    max-width: 858px;
  }
`;

const Ticket = styled.div<{ twoWay: boolean }>`
  width: 100%;
  height: ${({ twoWay }) => (twoWay ? 377 : 216)}px;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  border: 1px solid #dcdcdc;
`;

const TicketLeftPart = styled.div`
  padding: 20px 28px;
  width: 100%;
  max-width: 600px;
  border-right: 1px solid #dcdcdc;
  @media (max-width: 767px) {
    width: 100%;
    border: 0;
    padding: 14px 16px;
  }
`;

const TicketRightPart = styled.div`
  padding: 64px 25px 20px 25px;
  width: 225px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;

  @media (max-width: 767px) {
    display: none;
  }

  & > :first-child {
    width: 100%;
    height: 94px;
    border-radius: 8px;
    ${background}
  }

  & > :last-child {
    width: 50%;
    height: 18px;
    border-radius: 8px;
    ${background}
  }
`;

const Flight = styled.div`
  display: flex;
  flex-direction: column;
`;

const FlightHeader = styled.div`
  width: 100%;
  height: 25px;
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 20px;

  & > :first-child {
    width: 107px;
    height: 25px;
    border-radius: 8px;
    ${background}
  }

  & > :last-child {
    width: 50px;
    height: 25px;
    border-radius: 8px;
    ${background}
  }
`;

const FlightMain = styled.div`
  width: 100%;
  height: 67px;
  border-radius: 8px;
  ${background}
`;

const FlightFooter = styled.div`
  width: 178px;
  height: 25px;
  margin-top: 10px;
  border-radius: 8px;
  ${background}
`;

const Separator = styled.div`
  width: 100%;
  height: 1px;
  margin: 14px 0;
  ${background}
`;

const FlexWithMargin = styled(Flex)`
  margin-bottom: 15px;
`;

export const FilterSkeleton: React.FC = () => {
  return (
    <FilterWrapper>
      {Array(8)
        .fill('')
        .map((_, i) => (
          <FilterItem key={i}>
            <FilterText
              width={[110, 98, 63, 215, 99, 132, 205, 116][i]}
              marginBottom={i === 0 ? 24 : 0}
            />
            {i === 0 && (
              <>
                <FlexWithMargin>
                  <MiniText width={47} marginBottom={0} />
                  <MiniText width={47} marginBottom={0} />
                </FlexWithMargin>
                <Slider>
                  <Round />
                  <Row />
                  <Round />
                </Slider>
              </>
            )}
          </FilterItem>
        ))}
    </FilterWrapper>
  );
};

export const TicketListSkeleton: React.FC<TicketListSkeletonProps> = ({
  twoWay,
}) => {
  return (
    <List>
      {Array(3)
        .fill('')
        .map((_, key) => (
          <Ticket key={key} twoWay={twoWay}>
            <TicketLeftPart>
              <Flight>
                <FlightHeader>
                  <div />
                  <div />
                </FlightHeader>
                <FlightMain />
                <FlightFooter />
              </Flight>
              {twoWay && (
                <>
                  <Separator />
                  <Flight>
                    <FlightHeader>
                      <div />
                      <div />
                    </FlightHeader>
                    <FlightMain />
                    <FlightFooter />
                  </Flight>
                </>
              )}
            </TicketLeftPart>
            <TicketRightPart>
              <div />
              <div />
            </TicketRightPart>
          </Ticket>
        ))}
    </List>
  );
};

const Skeleton: React.FC<TicketListSkeletonProps> = (props) => {
  return (
    <Flex>
      <FilterSkeleton />
      <TicketListSkeleton twoWay={props.twoWay} />
    </Flex>
  );
};

export default Skeleton;
