import React, { useCallback } from 'react';
import styled from 'styled-components';
import { Text } from '@components/ui';
import { AllAdditionalTypes } from './types';
import { Helper } from '@utils';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteAddictionalService,
  deleteInsurances,
  cancelNotification,
} from '@modules/orders';
import deleteServiceIcon from '@assets/media/deleteServiceIcon.svg';
import { RoundIcon } from '@components/loader';
import moment, { Moment } from 'moment';

const Item = styled.div`
  padding: 20px 14px;
  background: #fff3cd;
  border: 1px solid #ffb800;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  min-height: 238px;
  box-sizing: border-box;
  position: relative;
`;

const ItemHead = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
`;

// const CancelButton = styled.button`
//   background: transparent;
//   border: none;
//   outline: none;
//   font-family: 'Open Sans';
//   font-style: normal;
//   font-weight: 400;
//   font-size: 14px;
//   line-height: 18px;
//   color: #4872f2;
//   cursor: pointer;
//   transition: color 0.2s ease-in-out;

//   &:hover {
//     color: #b5b3b3;
//   }
// `;

const ItemTitle = styled(Text)`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #3c3c3c;

  @media (max-width: 767px) {
    font-size: 14px;
    line-height: 18px;
  }
`;

const BaseText = styled(Text)`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #3c3c3c;

  & > strong {
    font-weight: 600;
  }
`;

const ItemDescription = styled(BaseText)`
  display: block;
  margin-bottom: 20px;

  @media (max-width: 767px) {
    margin-bottom: 15px;
  }
`;

const Information = styled.div`
  margin-bottom: 52px;

  & > span {
    margin-bottom: 10px;
    display: block;
  }

  & > :last-child {
    margin-bottom: 0;
  }
`;

const LoaderWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  background: white;
  z-index: 100;
`;

const ServicesList = styled.div`
  margin-left: 9px;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 3px;
`;

const SubRow = styled.div`
  display: flex;
  align-items: center;
`;

const DeleteServiceIcon = styled.img.attrs({ src: deleteServiceIcon })`
  cursor: pointer;
  margin-right: 5px;
`;

const SmallBaseText = styled(BaseText)`
  font-size: 12px;
`;

const BlueSpan = styled.span`
  color: #4872f2;
`;

function getServiceNameByType(type: string) {
  switch (type) {
    case 'SMS_Service':
      return 'SMS-информирование';
    case 'Return_Guarantee':
      return 'Гарантия возврата билета';
    case 'Payment_Receipt':
      return 'Справка о стоимости заказа';
    default:
      return type;
  }
}

export default function Calculations({
  onPay,
  additionalServivesInfo,
  insuranceInfo,
  supportTicketCalculation,
}: AllAdditionalTypes) {
  const allAdditions = useCallback(() => {
    return [
      ...(additionalServivesInfo?.additionalServices || []),
      ...(insuranceInfo?.insurances || []),
      ...(Array.isArray(supportTicketCalculation)
        ? supportTicketCalculation
        : []),
    ].filter(Boolean);
  }, [additionalServivesInfo, insuranceInfo, supportTicketCalculation]);

  const additionalLoading = useSelector(
    (s: Record<string, any>) => s.orders.orderDetail.additionsLoading,
  );

  const dispatch = useDispatch();

  return (
    <Item>
      {!additionalLoading ? (
        <>
          <ItemHead>
            <ItemTitle>Доплата по заказу</ItemTitle>
          </ItemHead>
          <ItemDescription>
            Необходимо внести доплату по заказу.
          </ItemDescription>
          <Information>
            <BaseText>
              Сумма доплаты:{' '}
              <strong>
                {allAdditions()?.reduce(
                  (acc, x) =>
                    acc +
                    (x.price ? x.price : x.supportTicketCalculation.total),
                  0,
                )}{' '}
                ₽
              </strong>{' '}
            </BaseText>
            <ServicesList>
              {(allAdditions() || []).map((x) => (
                <>
                  <Row>
                    <SubRow>
                      <DeleteServiceIcon
                        onClick={() =>
                          x.type
                            ? dispatch(deleteAddictionalService([x.type]))
                            : x.productId
                              ? dispatch(deleteInsurances([x.productId]))
                              : dispatch(
                                  cancelNotification(
                                    x.supportTicketCalculation.supportTicketId,
                                  ),
                                )
                        }
                      />{' '}
                      <BaseText>
                        {x.type
                          ? getServiceNameByType(x.type)
                          : x?.name
                            ? x?.name
                            : x.supportTicketCalculation.type.description +
                              ' по обращению № ' +
                              x.number}
                      </BaseText>
                    </SubRow>
                    <BaseText>
                      {Helper.formatPrice(
                        x.price ? x.price : x.supportTicketCalculation.total,
                      )}
                    </BaseText>
                  </Row>
                  {x.timeLimit && (
                    <SmallBaseText>
                      Время на оплату:{' '}
                      <BlueSpan>
                        {(() => {
                          const moscowTimeLimit = moment(x.timeLimit).add(
                            3,
                            'hours',
                          );
                          const now = moment();

                          if (now.isSameOrBefore(moscowTimeLimit)) {
                            const duration = moment.duration(
                              moscowTimeLimit.diff(now),
                            );
                            return Helper.momentFormatDuration(duration as any);
                          } else {
                            return 'Истекло';
                          }
                        })()}
                      </BlueSpan>
                    </SmallBaseText>
                  )}
                </>
              ))}
            </ServicesList>
          </Information>
        </>
      ) : (
        <LoaderWrapper>
          <RoundIcon />
        </LoaderWrapper>
      )}

      {/* <ButtonBlock>
        <PrimaryButton
          onClick={() => {
            onPay({
              s: null,
              a: additionalServivesInfo && additionalServivesInfo,
              c: insuranceInfo && insuranceInfo,
            });
          }}
        >
          Внести доплату по расчету
        </PrimaryButton>
      </ButtonBlock> */}
    </Item>
  );
}
