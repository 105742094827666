import {
  AirCheckAvailabilityDto,
  IState,
  PriceEntity,
  AncillaryServiceEntity,
  FormikPassengerItemEntity,
  TariffEntity,
  GetInsuranceCalculateDto,
  AddictionalServicesState,
  GroupEntity,
  FlightEntity,
  FareEntity,
  SeatMapEntity,
  SegmentEntity,
  SeatMapState,
  SeatMapPassenger,
  PassengerAgeCategory,
  FareFamilyAvailabilitiesTariffEntity,
  AncillaryServiceType,
  RisksEntity,
  AddictionalServiceTypes,
  StopEntity,
  PriceItem,
  FromToEntity,
  SegmentPlaceEntity,
} from './types';
import moment from 'moment';
import _, { memoize } from 'lodash';
import { Helper } from '@utils';

import insuranceCancelSrc from '@assets/media/insurances/insurance1.svg';
import insuranceProtectionSrc from '@assets/media/insurances/insurance2.svg';
import insuranceCovidSrc from '@assets/media/insurances/insurance3.svg';
import insuranceDelaySrc from '@assets/media/insurances/insurance4.svg';
import insuranceWeatherSrc from '@assets/media/insurances/insurance5.svg';
import insuranceDoctorSrc from '@assets/media/insurances/insurance6.svg';
import insurance7 from '@assets/media/insurances/insurance7.svg';
import insurance7TabIcon from '@assets/media/insurances/insurance7TabIcon.svg';

import alphastrahPath from '@assets/media/insurances/alphastrah.png';
import insurionPath from '@assets/media/insurances/insurion.png';
import CovidDeath from '@assets/images/booking/covidDeath.svg';
import Sun from '@assets/images/booking/sun.svg';
import Hearth from '@assets/media/insurances/hearth.svg';
import { FlightType } from '@components/booking/FlightDetail/types';
import {
  FareFamily,
  OrderDetailState,
  PriceDetalizationItem,
  Segment,
} from '@modules/orders';

export const createBookingDto = (
  ticket: IState,
  ancillaryServices: ReturnType<typeof createServices>,
  upsellIndex: number | null,
  payload: any,
  addictionalServices: AddictionalServicesState,
  seatMapState: SeatMapState,
  agentId: string | null,
  pluginToken: string,
): any => {
  const ancillaryKey = _(ancillaryServices)
    .map((x) =>
      _(x.offers)
        .map((y) =>
          _(y)
            .map((z) => z.filter((z) => z.checked).map((z) => z.key))
            .value(),
        )
        .value(),
    )
    .flattenDeep()
    .value();

  const seats = _(seatMapState.list)
    .map((x) =>
      x
        .filter((y) => y.seat != null)
        .map((y) => ({
          personUid: y.uid,
          segmentUid: y.segmentId,
          number: y.seat?.number,
          rfisc: y.seat?.rfisc,
          onlyForAdult: y.seat?.onlyForAdult,
        })),
    )
    .flatten()
    .value();

  const isSmsService = addictionalServices.find(
    (x) => x.checked && x.type === AddictionalServiceTypes.SMS_Service,
  );

  const result: any = {
    CheckAvailabilityResponseId: ticket.id,
    UpsaleResponseIndex: upsellIndex,

    Passengers: payload.passengers.map(
      (passenger: FormikPassengerItemEntity) => {
        const ageCategory = passenger.ageCategory.toUpperCase();

        if (passenger.dateOfIssue) {
          return {
            Uid: passenger.uid,
            FirstName: passenger.nameEng,
            LastName: passenger.surnameEng,
            MiddleName: passenger.secondNameEng,
            PatronymicName: passenger.secondNameEng,
            FullName:
              passenger.surnameEng +
              ' ' +
              passenger.nameEng +
              ' ' +
              passenger.secondNameEng,
            DateOfBirth: moment(passenger.birthDate).format(
              'YYYY-MM-DDT00:00:00',
            ),
            Sex: passenger.sex === 'm' ? 'MALE' : 'FEMALE',
            DocumentType: passenger.documentType,
            DocumentInfo:
              passenger.documentInfo !== ''
                ? passenger.documentInfo
                : passenger.number.replace(/_/g, ''),

            DocumentSeries: passenger.documentSeries,

            DocumentExpiryDate: moment(passenger.dateOfIssue).format(
              'YYYY-MM-DDT00:00:00',
            ),
            Citizenship: passenger.citizenship,
            AdditionalInfo:
              passenger.loyality.length > 0
                ? passenger.loyality
                    ?.map((y, i) => y.code + ':' + y.value)
                    .toString()
                : null,
            AgeCategory: ageCategory,
          };
        } else if (!passenger.dateOfIssue) {
          return {
            Uid: passenger.uid,
            FirstName: passenger.nameEng,
            LastName: passenger.surnameEng,
            MiddleName: passenger.secondNameEng,
            PatronymicName: passenger.secondNameEng,
            FullName:
              passenger.surnameEng +
              ' ' +
              passenger.nameEng +
              ' ' +
              passenger.secondNameEng,
            DateOfBirth: moment(passenger.birthDate).format(
              'YYYY-MM-DDT00:00:00',
            ),
            Sex: passenger.sex === 'm' ? 'MALE' : 'FEMALE',
            DocumentType: passenger.documentType,
            DocumentInfo: passenger.documentInfo
              ? passenger.documentInfo
              : passenger.number.replace(/_/g, ''),
            DocumentSeries: passenger.documentSeries,

            Citizenship: passenger.citizenship,
            AdditionalInfo:
              passenger.loyality.length > 0
                ? passenger.loyality
                    ?.map((y, i) => y.code + ':' + y.value)
                    .toString()
                : null,
            AgeCategory: ageCategory,
          };
        }
      },
    ),
    agentId: agentId,

    seats,
    InsuranceProductIds: payload.InsuranceProductIds,
    ContactEmail: payload.contactEmail,
    ContactPhone: payload.contactPhone.replaceAll(/\+|\(|\)|-|\s/g, ''),
    contactName: payload.contactName,
    salesChannel: payload.salesChannel,
    OfferPrice: payload.OfferPrice,
    BookingType: payload.BookingType,

    insuranceAgreement: payload.isInsurancesChecked,
    changeAgreementToMailing: {
      isNeedToChange: true,
      value: payload.emailOfferta,
    },
    AdditionalServiceIds: _(addictionalServices)
      .filter((x) => x.checked)
      .map((x) => x.id)
      .value(),
    PromoCode: '',
    PushXGUID: '',
    pluginToken: pluginToken,
    AncillaryKey: ancillaryKey.length > 0 ? ancillaryKey.join(',') : null,
    additionalContactInfo: null,
  };
  if (payload.marker !== null) {
    result.marker = payload.marker;
  }

  if (isSmsService) {
    result.additionalContactInfo = {
      name: payload.nameTwo,
      phone: payload.phoneTwo.replaceAll(/\(|\)|-|\s/g, ''),
    };

    if (payload.sameContactInfo) {
      result.additionalContactInfo = {
        name: payload.name,
        phone: payload.phone.replaceAll(/\(|\)|-|\s/g, ''),
      };
    }
  }

  return result;
};

// Check availability response normalizer

const createSeatMap = (args: TariffEntity) => {
  const arr = args.groups
    .map((x) =>
      x.flights
        .map((y) =>
          y.segments
            .map((z) => {
              if (z.seatMap === null) {
                return null;
              }
              return { ...z.seatMap, ...z, groupIndex: x.index };
            })
            .filter((z) => z !== null),
        )
        .flat(),
    )
    .flat()
    .filter((x) => x !== null);
  return arr as (SeatMapEntity & SegmentEntity & { groupIndex: number })[];
};

/**
 * Extract flights from unnecessary groups
 * Required for ticket reducer state
 * @param x group of flights, structure from avi search
 * @param flight_Type
 * @param gds
 */
const createFlightsList = (
  x: GroupEntity[],
  flight_Type: FlightType,
  gds: string,
) =>
  x.reduce(
    (
      acc: (FlightEntity & {
        flight_Type: FlightType;
        isLowcoster: boolean;
      })[],
      x,
    ) => [
      ...acc,
      ...x.flights.map((x) => ({
        ...x,
        flight_Type,
        gds,
        isLowcoster: !!x.segments.find((s) => s.isLowcoster),
      })),
    ],
    [],
  );

/**
 * Creating array of avia fares for fare reducer state
 * @param data CheckAvailabilityDTO
 */
const createFares = (data: AirCheckAvailabilityDto) => {
  const totalSum = memoize((arr: PriceEntity[]) =>
    arr.reduce((acc, x) => acc + x.total, 0),
  );
  const getPriceDiff = (x0: number) => (x1: number) => x1 - x0;
  const diffWithBasePrice =
    data.fares && getPriceDiff(totalSum(data.fares[0]?.tariff.prices || 0));

  function createFromToString(segmentGroups: GroupEntity[]) {
    return segmentGroups
      .map((value) => {
        if (value.flights.length === 1) {
          return [...value.flights, ...value.flights].map(extractCity);
        } else {
          return value.flights.map(extractCity);
        }
      })
      .map(_.flatten)
      .map((value, index, arr) => removeDuplicates(value, arr[index + 1]))
      .map((value) => value?.join(' - '))
      .join(' - ');
  }

  function extractCity(
    value: FlightEntity,
    index: number,
    arr: FlightEntity[],
  ) {
    if (index !== 0 && index !== arr.length - 1)
      return [
        value.segments[0].from.city,
        value.segments[value.segments.length - 1].to.city,
      ];
    return index === 0
      ? value.segments[0].from.city
      : value.segments[0].to.city;
  }

  function removeDuplicates(x1: string[], x2?: string[]) {
    if (!x2) {
      return x1;
    }
    if (x1[x1.length - 1] === x2[0]) {
      const x1Clone = [...x1];
      x1Clone.length = x1.length - 1;
      return x1Clone;
    }
  }

  const createFare =
    (upsellIndex: number | null) =>
    <T extends TariffEntity & { ancillaryServices?: AncillaryServiceEntity[] }>(
      x: T,
      upt: string,
      timeLimitDuration: string,
    ) => {
      const isMultipleFareFamilies =
        !!x.fareFamilies.length && x.fareFamilies.length > 1;

      const durationMinutes = Math.trunc(
        moment.duration(timeLimitDuration).asMinutes(),
      );

      const result: FareEntity = {
        extraPrice: diffWithBasePrice(totalSum(x.prices)),
        prices: x.prices,
        extraCharge: x.extraCharge,
        fareFamilies: x.fareFamilies,
        fromTo: createFromToString(x.groups),
        upt: upt,
        gds: x.gds,
        groups: x.groups,
        timeLimitDuration: Helper.formatDuration(durationMinutes),
        upsellIndex,
        seatMap: createSeatMap(x),
        services: x.ancillaryServices,
        flight_Type: x.groups[0].flights[0].segments[0].flight_Type,
        isHealthDeclarationChecked: x?.aviacenter?.isHealthDeclarationChecked,
        healthDeclarationText: x?.aviacenter?.healthDeclarationText,
        hasConnectingFlights: x?.hasConnectingFlights,
        isMultipleFareFamilies: isMultipleFareFamilies,
      };
      return result;
    };

  return data?.fares?.map((fare) => {
    return createFare(fare.upsellIndex)(
      {
        ...fare.tariff,
        ...fare.supplierInfo,
        ancillaryServices: fare.ancillaryServices,
      },
      fare.upt,
      fare.timeLimitDuration,
    );
  });
};

/** Passengers */

/**
 * Create passenger list, required for passenger reducer state
 * @param passengers
 */

/** Services */

const groupServicesByType = (services: AncillaryServiceEntity[]) => {
  return _(services)
    .groupBy('type')
    .map((value, key) => {
      return {
        type: key as AncillaryServiceType,
        offers: _(value)
          .map(({ type, offers, ...rest }) => {
            return offers.map((x) => ({ ...x, ...rest }));
          })
          .flatten()
          .value(),
      };
    })
    .value();
};

const createServiceFunc =
  (tariff: FareFamilyAvailabilitiesTariffEntity) =>
  (service: ReturnType<typeof groupServicesByType>[number]) => {
    const segments = _(tariff.groups)
      .reduce(
        (acc, x) => [
          ...acc,
          ...x.flights.reduce(
            (acc, x) => [...acc, ...x.segments],
            [] as (typeof tariff)['groups'][number]['flights'][number]['segments'],
          ),
        ],
        [] as (typeof tariff)['groups'][number]['flights'][number]['segments'],
      )
      .map((x) => ({
        id: x.id,
        route: `${x.from.city} - ${x.to.city}`,
        fulfilled: false,
      }));

    if (segments.length !== 1) {
      const concatSegments = segments.reduce(
        (acc, x, i) => acc + x.id + (i + 1 !== segments.length ? '-' : ''),
        '',
      );

      let counterId = 0;

      const segmentsWithStopsId = segments.reduce((acc: string[], curr) => {
        if (counterId === 0) {
          acc.push(curr.id);
          counterId++;
        } else {
          acc[acc.length - 1] += '-' + curr.id;
          counterId = 0;
        }
        return acc;
      }, []);

      let counterName = 0;

      const segmentsWithStopsName = segments.reduce((acc: string[], curr) => {
        if (counterName === 0) {
          acc.push(curr.route);
          counterName++;
        } else {
          acc[acc.length - 1] += ', ' + curr.route;
          counterName = 0;
        }
        return acc;
      }, []);

      const fakeSegments = segments.slice(2);

      let counterId2 = 0;

      const segmentsWithStopsId2 = fakeSegments.reduce(
        (acc: string[], curr) => {
          if (counterId2 === 0) {
            acc.push(curr.id);
            counterId2++;
          } else if (counterId2 === 1) {
            acc[acc.length - 1] += '-' + curr.id;
            counterId2++;
          } else if (counterId2 === 2) {
            acc[acc.length - 1] += '-' + curr.id;
            counterId2 = 0;
          }
          return acc;
        },
        [],
      );

      let counterName2 = 0;

      const segmentsWithStopsName2 = fakeSegments.reduce(
        (acc: string[], curr) => {
          if (counterName2 === 0) {
            acc.push(curr.route);
            counterName2++;
          } else if (counterName2 === 1) {
            acc[acc.length - 1] += ', ' + curr.route;
            counterName2++;
          } else if (counterName2 === 2) {
            acc[acc.length - 1] += ', ' + curr.route;
            counterName2 = 0;
          }
          return acc;
        },
        [],
      );

      const fakeSegments2 = segments.slice(0, -2);

      let counterId3 = 0;

      const segmentsWithStopsId3 = fakeSegments2.reduce(
        (acc: string[], curr) => {
          if (counterId3 === 0) {
            acc.push(curr.id);
            counterId3++;
          } else if (counterId3 === 1) {
            acc[acc.length - 1] += '-' + curr.id;
            counterId3++;
          } else if (counterId3 === 2) {
            acc[acc.length - 1] += '-' + curr.id;
            counterId3 = 0;
          }
          return acc;
        },
        [],
      );

      let counterName3 = 0;

      const segmentsWithStopsName3 = fakeSegments2.reduce(
        (acc: string[], curr) => {
          if (counterName3 === 0) {
            acc.push(curr.route);
            counterName3++;
          } else if (counterName3 === 1) {
            acc[acc.length - 1] += ', ' + curr.route;
            counterName3++;
          } else if (counterName3 === 2) {
            acc[acc.length - 1] += ', ' + curr.route;
            counterName3 = 0;
          }
          return acc;
        },
        [],
      );

      const fakeSegments3 = segments.slice(3);

      let counterId4 = 0;

      const segmentsWithStopsId4 = fakeSegments3.reduce(
        (acc: string[], curr) => {
          if (counterId4 === 0) {
            acc.push(curr.id);
            counterId4++;
          } else if (counterId4 === 1) {
            acc[acc.length - 1] += '-' + curr.id;
            counterId4++;
          } else if (counterId4 === 2) {
            acc[acc.length - 1] += '-' + curr.id;
            counterId4 = 0;
          }
          return acc;
        },
        [],
      );

      let counterName4 = 0;

      const segmentsWithStopsName4 = fakeSegments3.reduce(
        (acc: string[], curr) => {
          if (counterName4 === 0) {
            acc.push(curr.route);
            counterName4++;
          } else if (counterName4 === 1) {
            acc[acc.length - 1] += ', ' + curr.route;
            counterName4++;
          } else if (counterName4 === 2) {
            acc[acc.length - 1] += ', ' + curr.route;
            counterName4 = 0;
          }
          return acc;
        },
        [],
      );

      segmentsWithStopsId.map((x: string, i) => {
        segments.push({
          id: x,
          route: segmentsWithStopsName[i],
          fulfilled: false,
        });
      });

      segmentsWithStopsId2.map((x: string, i) => {
        segments.push({
          id: x,
          route: segmentsWithStopsName2[i],
          fulfilled: false,
        });
      });

      segmentsWithStopsId3.map((x: string, i) => {
        segments.push({
          id: x,
          route: segmentsWithStopsName3[i],
          fulfilled: false,
        });
      });

      segmentsWithStopsId4.map((x: string, i) => {
        segments.push({
          id: x,
          route: segmentsWithStopsName4[i],
          fulfilled: false,
        });
      });

      segments.push({
        id: concatSegments,
        route: 'ВСЕ СЕГМЕНТЫ',
        fulfilled: false,
      });
    }

    const groupedSegments = segments.reduce(
      (acc, x) => ({ ...acc, [x.id]: x }),
      {} as { [key: string]: (typeof segments)[0] },
    );

    const offers = _(service.offers)
      .groupBy((x) => x.flightSegmentsUid.join('-'))
      .mapValues((x) => {
        return _(x)
          .groupBy((x) => x.passengerId)
          .mapValues((x) => {
            return x.map((x) => ({
              ...x,
              checked: false,
              segmentName: groupedSegments[x.flightSegmentsUid[0]]?.route,
              cost: x.total,
              title:
                service.type === AncillaryServiceType.Luggage
                  ? x.luggageInfo
                    ? `Багаж до ${x.luggageInfo?.onePlaceWeight} кг`
                    : x.name
                  : x.name,
            }));
          })
          .value();
      })
      .value();

    const MIN_PRICE = Math.min(...service.offers.map((x) => x.total));

    const availableSegmentsIds = _.uniq(
      service.offers.map((x) => x.flightSegmentsUid.join('-')),
    );
    const availableSegments = [...segments]
      .filter((x) => availableSegmentsIds.includes(x.id))
      .filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.id === value.id),
      );

    return {
      segments: availableSegments,
      groupedSegments,
      offers,
      minPrice: MIN_PRICE,
      type: service.type,
    };
  };

export function createServiceAddictionalInfo(
  service: ReturnType<ReturnType<typeof createServiceFunc>>,
) {
  const result = _(service.offers)
    .map((x, key) => {
      const passengers = _(x)
        .map((x, key) => {
          const offers = _(x)
            .filter((x) => x.checked)
            .map((x) => {
              let name = x.name;
              if (service.type === AncillaryServiceType.Luggage) {
                name = x.luggageInfo
                  ? `Багаж до ${x.luggageInfo.onePlaceWeight} кг`
                  : 'Без багажа';
              }
              return { price: x.cost, name, key: x.key };
            })
            .value();

          return {
            key,
            offers,
            price: _(offers).reduce((acc, x) => acc + x.price, 0),
          };
        })
        .filter((x) => x.offers.length > 0)
        .value();
      return {
        segmentName: service.groupedSegments[key]?.route,
        key,
        passengers,
        price: _(passengers).reduce((acc, x) => acc + x.price, 0),
      };
    })
    .filter((x) => x.passengers.length > 0)
    .value();

  return result;
}

export const createServices = (
  services: AncillaryServiceEntity[],
  tariff: FareFamilyAvailabilitiesTariffEntity,
) => {
  const cb = createServiceFunc(tariff);
  return groupServicesByType(services || [])
    .map(cb)
    .map((x) => ({ ...x, addictionalInfo: createServiceAddictionalInfo(x) }));
};

/**
 * if argument is null generate default object, else normalize it
 * Required for passenger and services reducers state
 * @param arg
 */

export function createPassengersArray(args: AirCheckAvailabilityDto) {
  if ((args.passengers || []).length < 1) {
    return [];
  }

  const passengers = args.passengers.map((x) => ({
    name: '',
    nameEng: '',
    surname: '',
    surnameEng: '',
    secondName: '',
    secondNameEng: '',
    ageCategoryDescription: Helper.translatePassengerAgeCategory(x.ageCategory),
    documentType: x.ageCategory === 'ADULT' ? 'PASSPORT' : 'BIRTH_CERTIFICATE',
    ageCategory: x.ageCategory,
    uid: x.uid,
  }));

  return passengers;
}

export function setSegmentsAccessibility(state: SeatMapState) {
  let availableSeatCount = 0;
  const segments = Object.entries(state.list).map(([key, x]) => {
    const count = x.filter((x) => x.seat === null).length;
    availableSeatCount += count;
    return {
      name: x[0].segmentName,
      haveEmptySelectedPlaces: count > 0,
      id: key,
    };
  });
  return {
    availableSeatCount,
    segmentsAccessibility: segments,
  };
}

export const checkAvailabilityNormalizer = (arg: AirCheckAvailabilityDto) => {
  return {
    ...arg,
    frequentFlyerAirlines: arg.frequentFlyerAirlines,
    fares: createFares(arg),
    ancillaryServices:
      arg.fares &&
      createServices(arg.fares[0].ancillaryServices, arg.fares[0].tariff),
    passengers: createPassengersArray(arg),
    flights:
      arg.fares &&
      createFlightsList(
        arg.fares[0].tariff.groups,
        arg.fares[0].tariff.groups[0].flights[0].segments[0].flight_Type,
        arg.fares[0].tariff.gds,
      ),
    flightSearchParameters: arg.flightSearchParameters,
  };
};

export function createSeatMapState(
  fare: ReturnType<typeof createFares>[number],
  passengers: ReturnType<typeof createPassengersArray>,
): SeatMapState {
  const segments: { [key: string]: SeatMapPassenger[] } = {};

  fare.seatMap.map((x, key) => {
    segments[' ' + x.flightNo] = passengers
      .filter((x) => x.ageCategory !== PassengerAgeCategory.Infant)
      .map((y) => ({
        ...y,
        seat: null,
        segmentId: x.id,
        segmentName: `${x.from.code}-${x.to.code}`,
      }));
  });

  const pricesArr = _(fare.seatMap || [])
    .map((x) =>
      x.decks?.map((y) =>
        y.rows.map((z) =>
          z.seatGroups.map((d) => d.seats.map((g) => g.price.amount)),
        ),
      ),
    )
    .flattenDeep()
    .sort((a, b) => a - b)
    .value();

  const priceArrUnique = _(pricesArr).filter(Boolean).uniq().value();
  const CHUNK_COUNT = priceArrUnique.length > 2 ? 3 : priceArrUnique.length;
  const CHUNK_SIZE = Math.ceil(priceArrUnique.length / CHUNK_COUNT);
  const pricesForColor: number[] = [];
  let count = 0;

  let i = 0;
  let j = 0;

  while (count < CHUNK_COUNT || i < priceArrUnique.length) {
    if (j === 0) {
      pricesForColor.push(priceArrUnique[i]);
      count += 1;
    }
    j += 1;
    i += 1;
    if (j === CHUNK_SIZE) {
      j = 0;
    }
  }

  if (!pricesForColor[CHUNK_COUNT - 1]) {
    pricesForColor[CHUNK_COUNT - 1] = priceArrUnique[priceArrUnique.length - 1];
  }

  const minPrice = Math.min(...pricesArr.filter(Boolean));

  const result = {
    minPrice,
    selectedPassengerId: passengers[0].uid,
    selectedPassengerType: passengers[0].ageCategory,
    selectedSegmentNumber: ' ' + fare.seatMap[0]?.flightNo || '',
    available: fare.seatMap.length > 0,
    totalPrice: 0,
    selectedSeatCount: 0,
    availableSeatCount: 0,
    segmentsAccessibility: [],
    loading: false,
    list: segments,
    priceArrForColors: pricesForColor,
  };

  return { ...result, ...setSegmentsAccessibility(result) };
}

function getInsuranceIcon(productType: string) {
  switch (productType) {
    case 'ANTICOVID':
      return insuranceCovidSrc;
    case 'Защита на время полета':
      return insuranceProtectionSrc;
    case 'Отмена поездки':
      return insuranceCancelSrc;
    case 'Страховка от задержки рейса':
    case 'Задержка рейса':
      return insuranceDelaySrc;
    case 'Гарантия хорошей погоды для туристов':
    case 'Гарантия хорошей погоды':
      return insuranceWeatherSrc;
    case 'Online-врач в поездку':
      return insuranceDoctorSrc;
    case 'Полёт под защитой':
      return insurance7;
  }
}

function getInsuranceDescription(productType: string) {
  switch (productType) {
    case 'ANTICOVID':
      return 'Нет ничего важнее Вашего здоровья. Получите выплату при заболевании в поездке.';
    case 'Защита на время полета':
      return 'Получите выплату на карту при задержке рейса, потере багажа и других страховых случаях.';
    case 'Отмена поездки':
      return 'Компенсация до 5 000 ₽ при отмене или изменении сроков перелёта по одной из причин:';
    case 'Online-врач в поездку':
      return 'Дистанционная квалифицированная медицинская помощь терапевта или педиатра через приложение на всё время поездки в любой точке мира.';
    case 'Страховка от задержки рейса':
    case 'Задержка рейса':
      return 'Моментальная выплата от 3000 ₽ на карту, в случае задержки рейса от 3ч.';
    case 'Гарантия хорошей погоды для туристов':
    case 'Гарантия хорошей погоды':
      return 'Моментальная выплата 5000 ₽ на карту, в случае плохой погоды во время отдыха.';
    case 'Полёт под защитой':
      return 'Моментальная выплата на карту при задержке рейса. Защита багажа и здоровья.';
  }
}

function getCompany(productType: string) {
  switch (productType) {
    case 'Задержка рейса':
    case 'Гарантия хорошей погоды':
    case 'Гарантия хорошей погоды для туристов':
    case 'Страховка от задержки рейса':
      return { name: 'Insurion', logo: insurionPath, phone: null };
    default:
      return {
        name: 'АльфаСтрахование',
        logo: alphastrahPath,
        phone: '8-499-785-0999',
      };
  }
}

function getLabel(productType: string) {
  switch (productType) {
    case 'ANTICOVID':
      return { color: '#daf0a2', text: 'Самое важное', icon: CovidDeath };
    case 'Гарантия хорошей погоды':
    case 'Гарантия хорошей погоды для туристов':
      return { color: '#96D9FF', text: 'Без дождя', icon: Sun };
    case 'Online-врач в поездку':
      return { color: '#DAF0A2', text: 'Для всей семьи', icon: Hearth };
    case 'Полёт под защитой':
      return {
        color: '#FFBCBC',
        text: 'Выплата сразу',
        icon: insurance7TabIcon,
      };
    default:
      return null;
  }
}

function formatRisks(productType: string, risks: RisksEntity[]): RisksEntity[] {
  switch (productType) {
    case 'ANTICOVID':
      return [
        {
          id: '',

          coverage: { value: 15000, currency: 'RUB' },
          description: 'в случае диагностики COVID-19',
        },
        {
          id: '',
          coverage: { value: 30000, currency: 'RUB' },
          description: 'в случае госпитализации с COVID-19',
        },
      ];
    case 'Защита на время полета':
      return risks.map((x) => {
        let description = x.description;

        if (x.description.includes('Потеря багажа')) {
          description = 'при пропаже багажа';
        } else if (x.description.includes('Задержка рейса')) {
          description = 'при задержке рейса';
        } else if (x.description.includes('Несчастный случай')) {
          description = 'в несчастном случае';
        } else if (x.description.includes('Задержка багажа')) {
          description = 'при задержке багажа';
        }

        return { ...x, description };
      });
    case 'Отмена поездки':
      return risks.map((x) => ({
        ...x,
        description:
          'в случае внезапной отмены поездки из-за отказа в визе, травмы, госпитализации и еще 13 причин',
      }));
    default:
      return risks;
  }
}

function getWordBeforPrice(productType: string) {
  switch (productType) {
    case 'Страховка от задержки рейса':
    case 'Задержка рейса':
      return 'от';
    case 'Гарантия хорошей погоды для туристов':
    case 'Гарантия хорошей погоды':
      return '';
    default:
      return 'до';
  }
}

function getSpecificationDoc(productType: string) {
  switch (productType) {
    case 'Страховка от задержки рейса':
    case 'Задержка рейса':
    case 'Гарантия хорошей погоды для туристов':
    case 'Гарантия хорошей погоды':
      return '/docs/reno.pdf';
    case 'Отмена поездки':
      return 'https://www.alfastrah.ru/docs/insurance_passengers_rules_284.pdf';
    default:
      return 'http://www.alfastrah.ru/docs/rules_SP_230617.pdf';
  }
}

function getOffertaUri(productType: string) {
  switch (productType) {
    case 'ANTICOVID':
      return 'https://www.alfastrah.ru/docs/Offer_anticovid_2_avia_247.pdf';
    case 'Защита на время полета':
      return 'https://www.alfastrah.ru/docs/Offer_bg_zv_ns_247.pdf';
    case 'Отмена поездки':
      return 'http://www.alfastrah.ru/docs/Offer_nsp_247_se.pdf';
    case 'Online-врач в поездку':
      return 'https://www.alfastrah.ru/docs/telemed_ns_offer.pdf';
    default:
      return null;
  }
}

export function normalizeGetInsuranceCalculationResponse(
  args: GetInsuranceCalculateDto,
) {
  const res = _(args.offers)
    .map((item) => {
      return {
        ...item,
        offerByProducts: item.offerByProducts.map((offer) => {
          const productType = (offer.product.name || '').trim();

          return {
            ...offer,
            checked: false,
            wordBeforePrice: getWordBeforPrice(productType),
            iconSrc: getInsuranceIcon(productType),
            company: getCompany(productType),
            label: getLabel(productType),
            loading: false,
            offertaUri: getOffertaUri(productType),
            specificationUri: getSpecificationDoc(productType),
            risks: formatRisks(productType, offer.risks || []).sort(
              (a, b) => a.coverage.value - b.coverage.value,
            ),
            product: {
              ...offer.product,
              description: getInsuranceDescription(productType),
            },
          };
        }),
      };
    })
    .value();

  return res;
}

export const url = (searchRequest: any) => {
  return (
    searchRequest.route[0].from +
    (moment(searchRequest.route[0].date)
      .format('DD-MM-YYYY')
      .split('-')
      .join('')
      .substring(0, 4) +
      moment(searchRequest.route[0].date)
        .format('DD-MM-YYYY')
        .split('-')
        .join('')
        .substring(6, 8)) +
    (searchRequest.route[0].to ? searchRequest.route[0].to : '') +
    (searchRequest?.route[1]?.date
      ? moment(searchRequest.route[1].date)
          .format('DD-MM-YYYY')
          .split('-')
          .join('')
          .substring(0, 4) +
        moment(searchRequest.route[1].date)
          .format('DD-MM-YYYY')
          .split('-')
          .join('')
          .substring(6, 8)
      : '') +
    (searchRequest.passengers.adults ? searchRequest.passengers.adults : '') +
    (searchRequest.passengers.children
      ? searchRequest.passengers.children
      : '') +
    (searchRequest.passengers.infants ? searchRequest.passengers.infants : '')
  );
};

export const orderDetailsStateToFlightEntity = (
  order: OrderDetailState,
  orderPositionIndex = 0,
): FlightEntity[] => {
  const result: FlightEntity[] = [];
  if (!order?.orderPositions[0]) {
    return [];
  }
  const fareFamilies =
    order?.orderPositions[orderPositionIndex].tariff.fareFamilies;
  const flight_Type = order?.orderPositions[orderPositionIndex].flight_Type;
  order.orderPositions[orderPositionIndex].segmentGroups.forEach(
    (segmentGroup, index) => {
      const segments = segmentGroup.segments.map((segment, index) => {
        const fromToRoute = {
          from: segment.departureAirport.code,
          to: segment.arrivalAirport.code,
        };

        const fareFamily = fareFamilies
          ? fareFamilies.find((fareFamily) => {
              return fareFamily?.routes?.some((route) => {
                return (
                  route.from === fromToRoute.from && route.to === fromToRoute.to
                );
              });
            })
          : null;
        return segmentEntityToSegment(segment, index, flight_Type, fareFamily);
      });
      let duration = segments.reduce((acc, x) => acc + x.duration, 0);
      const stops: StopEntity[] = [];
      segmentGroup.segments.forEach((segment, index) => {
        if (segment.stopOvers.length) {
          duration += segment.stopOvers[index]?.duration;
          stops.push({
            duration: segment.stopOvers[index]?.duration,
            city: {
              code: segment.arrivalCity.code,
              airport: segment.arrivalAirport?.name,
              city: segment.arrivalCity?.name,
              country: '',
            },
            time: moment(segment.stopOvers[index]?.arrivalDateTime).format(
              'HH:mm:ss',
            ),
          });
        }
      });
      result.push({
        amount: order.amount,
        duration: duration,
        index: index,
        segments: segments,
        minAvailSeats: 0,
        stops: stops,
        flight_Type: flight_Type,
        isLowcoster: false, // TODO: check
        time: 'TIME', // TODO: check
        transitVisaInfo: null, // TODO: check
        transitVisaInfoText: null, // TODO: check
      });
    },
  );
  return result;
};

const segmentEntityToSegment = (
  segment: Segment,
  index: number,
  flight_Type: FlightType,
  fareFamily?: FareFamily | null,
): SegmentEntity => {
  let luggageDescription = '';
  if (fareFamily) {
    const luggageFeature = fareFamily.features.find(
      (feature) => feature.type === 'Baggage',
    );
    if (luggageFeature) {
      luggageDescription = luggageFeature.descriptionRus;
    }
  }
  return {
    index: index,
    fareFamilyFeature: null,
    techLandings: segment.techLandings,
    duration: segment.duration,
    flightNo: segment.flightNumber,
    airplane: segment.airplane?.name,
    operatingAirlineCode: segment.operatingAirline.code,
    operatingAirline: segment.operatingAirline.name,
    airline: segment.marketingAirline.name,
    airlineCode: segment.marketingAirline.code,
    from: {
      code: segment.departureAirport.code,
      airport: segment.departureAirport.name,
      city: segment.departureCity.name,
      country: '',
      terminal: segment.departureAirport.terminal,
    },
    to: {
      code: segment.arrivalAirport.code,
      airport: segment.arrivalAirport.name,
      city: segment.arrivalCity.name,
      country: '',
      terminal: segment.arrivalAirport.terminal,
    },
    fromDate: moment(segment.departureDateTime).format('YYYY-MM-DD'),
    fromTime: moment(segment.departureDateTime).format('HH:mm:ss'),
    id: segment.id,
    toDate: moment(segment.arrivalDateTime).format('YYYY-MM-DD'),
    toTime: moment(segment.arrivalDateTime).format('HH:mm:ss'),
    time: moment(segment.arrivalDateTime).format('PTH[H]mm[M]'),
    flightClass: segment.bookingClassRu,
    seatMap: null,
    fareBasis: '', // TODO: check
    availSeats: 0, // TODO: check,
    classCode: segment.bookingClass, // TODO: check
    fareFamily: null,
    isLowcoster: segment.isLowcoster,
    flight_Type: segment.flight_Type,
    luggageDescription: luggageDescription,
  };
};

export const orderTotalDetalizationToPriceDetails = (
  orderTotal: PriceDetalizationItem[],
): PriceItem[] => {
  const detailPriceItems: PriceItem[] = [];
  orderTotal.forEach((item, orderTotalIndex) => {
    if (item.detalization.length > 0) {
      if (item.priceType !== 5) {
        item.detalization.forEach((detalizationItem, itemIndex) => {
          detailPriceItems.push({
            description: detalizationItem.description,
            cost: detalizationItem.value,
            type: detalizationItem.priceType.toString(),
            quantity: 1, // Default quantity to 1 as it does not exist in PriceDetalizationItem
            id: `${orderTotalIndex}-${itemIndex}`,
            additionalPriceItems: orderTotalDetalizationToPriceDetails(
              detalizationItem.detalization,
            ),
          });
        });
      } else if (item.priceType === 5) {
        detailPriceItems.push({
          description: item.description,
          cost: item.value,
          type: item.priceType.toString(),
          quantity: 1,
          id: `${orderTotalIndex}+${item.value}`,
        });
      }
    } else {
      detailPriceItems.push({
        description: item.description,
        cost: item.value,
        type: item.priceType.toString(),
        quantity: 1, // Default quantity to 1 as it does not exist in PriceDetalizationItem
        id: `${orderTotalIndex}`,
      });
    }
  });

  return detailPriceItems;
};

/**
 * Change code to city in routes
 * @param routes
 * @param cities
 */
export const changeCodeToCityInRoutes = (
  routes: FromToEntity[],
  cities: SegmentPlaceEntity[],
): FromToEntity[] => {
  return routes?.map((route) => {
    const fromCity = cities.find((city) => city.code === route.from);
    const toCity = cities.find((city) => city.code === route.to);
    return {
      from: fromCity ? fromCity.city : route.from,
      to: toCity ? toCity.city : route.to,
    };
  });
};
