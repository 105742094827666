import React from 'react';
import * as Styled from './styles/Default.style';
import * as yup from 'yup';
import _ from 'lodash';
import { Textarea, Select, SelectWithCheckboxes } from '@components/ui/form';
import { Text } from '@components/ui';
import { Formik } from 'formik';
import { useDispatch } from 'react-redux';
import { addRefundReason } from '@modules/appeals';
import { useProps } from './useProps';
import { RefundHeader } from './static/Refund';
import { components } from 'react-select';
import {
  ContainerMultiList,
  ContainerMultiValue,
} from './styles/Default.style';
import { DefaultHeader } from './static/Default';
import useCreateFormDefault from './model/useCreateFormDefault';
import { PositionAppealCode } from '@app/components/order/Detail/OrderPositions/Item/types';

const isAddictionalServicesRefund = <T extends { code: string }>(args: T) =>
  args.code === PositionAppealCode.REFUND_ADDITIONAL_SERVICE;

const showPassengersAndSegments = (code: string) =>
  code !== 'Other' && code !== 'RefundInsurance';

const formikValidateSchema = yup.object().shape({
  message: yup.string().required('Заполните'),
});

const CreateFormPopup: React.FC = () => {
  const { orderPosition, defaultSubject } = useProps();
  const isRefundPopup = defaultSubject === PositionAppealCode.FULL_REFUND;
  const {
    state,
    innerDispatch,
    onSubmit,
    setFiles,
    insurancesOptions,
    initialValues,
    initialSubject,
    subjects,
    filteredRefundReasons,
    initialReason,
  } = useCreateFormDefault();

  const dispatch = useDispatch();

  return (
    <div style={{ width: '100%' }}>
      {isRefundPopup ? (
        <RefundHeader isTrainOrder={orderPosition?.isTrainOrder} />
      ) : (
        <DefaultHeader isTrainOrder={orderPosition?.isTrainOrder} />
      )}
      <Formik
        initialValues={initialValues}
        validationSchema={formikValidateSchema}
        onSubmit={onSubmit}
      >
        {({
          handleChange,
          handleBlur,
          setFieldValue,
          errors,
          submitCount,
          values,
          setErrors,
        }) => (
          <Styled.Form>
            {isRefundPopup ? (
              <Select
                isSearchable={false}
                onChange={(data: any) => {
                  setFieldValue('reason', data);
                  dispatch(addRefundReason(data));
                }}
                defaultValue={initialReason}
                name="reason"
                label="Выберите причину возврата"
                options={filteredRefundReasons}
              />
            ) : (
              <Select
                isSearchable={false}
                onChange={(data: any) => {
                  setFieldValue('subject', data);
                }}
                defaultValue={initialSubject}
                name="subject"
                label="Выберите тему обращения"
                options={subjects}
              />
            )}
            {showPassengersAndSegments(values.subject.code) && (
              <>
                <Styled.AncillaryServicesBlock>
                  <SelectWithCheckboxes
                    label="Выберите пассажиров"
                    error={(errors as any).passengers}
                    items={state.passengerList}
                    onSelect={(data, action) => {
                      switch (action) {
                        case 'select':
                          innerDispatch({
                            type: 'addPassenger',
                            payload: data.value,
                          });
                          break;
                        case 'remove':
                          innerDispatch({
                            type: 'removePassenger',
                            payload: data.value,
                          });
                          break;
                      }
                    }}
                    onSelectAll={() => {
                      innerDispatch({
                        type: 'addAllPassengers',
                      });
                    }}
                    onClick={() => {
                      const newErrors = { ...errors } as any;
                      delete newErrors.passengers;
                      setErrors(newErrors);
                    }}
                    selectAllText="Выбрать всех пассажиров"
                  />
                </Styled.AncillaryServicesBlock>

                <Styled.AncillaryServicesBlock>
                  <SelectWithCheckboxes
                    label="Выберите сегменты маршрута"
                    error={(errors as any).segments}
                    items={
                      state.passengers[state.activePassenger]?.segmentList || []
                    }
                    onSelect={(data, action) => {
                      switch (action) {
                        case 'select':
                          innerDispatch({
                            type: 'addSegment',
                            payload: data.value,
                          });
                          break;
                        case 'remove':
                          innerDispatch({
                            type: 'removeSegment',
                            payload: data.value,
                          });
                          break;
                      }
                    }}
                    onSelectAll={() => {
                      innerDispatch({
                        type: 'addAllSegment',
                      });
                    }}
                    onClick={() => {
                      const newErrors = { ...errors } as any;
                      delete newErrors.segments;
                      setErrors(newErrors);
                    }}
                    selectAllText="Выбрать все сегменты"
                  />
                </Styled.AncillaryServicesBlock>

                {isAddictionalServicesRefund(values.subject) &&
                  state.activePassenger !== '' &&
                  state.availableAncillaries.length > 0 && (
                    <Styled.AncillaryServicesBlock>
                      <Select
                        isMulti={true as any}
                        isSearchable={false}
                        onChange={(data: any, meta: any) => {
                          setFieldValue(
                            'ancillaryServices',
                            (data || []).map((item: any) => item.value),
                          );

                          if (meta.action === 'select-option') {
                            innerDispatch({
                              type: 'addAncillary',
                              payload: meta.option.value,
                            });
                          } else if (meta.action === 'remove-value') {
                            innerDispatch({
                              type: 'removeAncillar',
                              payload: meta.removedValue.value,
                            });
                          }
                        }}
                        name="ancillaryServices"
                        label="Доп. услуги"
                        value={
                          state.availableAncillaries.filter(
                            (x) => !!state.mealNamesArr[x.label],
                          ) as any
                        }
                        placeholder=""
                        options={state.availableAncillaries}
                      />
                    </Styled.AncillaryServicesBlock>
                  )}
              </>
            )}
            {values.subject.code === 'RefundInsurance' && (
              <>
                <Styled.AncillaryServicesBlock>
                  <Select
                    isSearchable={false}
                    isMulti={true as any}
                    onChange={(data: any, meta: any) => {
                      if (meta.action === 'select-option') {
                        innerDispatch({
                          type: 'switchInsurance',
                          payload: { ...meta.option },
                        });
                      }
                    }}
                    name="insurances"
                    label="Страховка"
                    placeholder=""
                    options={insurancesOptions}
                    components={{
                      ValueContainer,
                      MultiValueContainer,
                      MultiValueRemove: () => null as any,
                    }}
                  />
                </Styled.AncillaryServicesBlock>
                {state.showPassengersList && (
                  <Styled.PassengersListWrapper>
                    {_(state.passengers)
                      .map((item, key) => {
                        return item.showPassengerInList ? (
                          <Styled.InsurancesPassenger key={key}>
                            <Styled.PassengerName>
                              {item.label}
                            </Styled.PassengerName>
                            <Styled.InsuranceList>
                              {item.insurances
                                .filter((item) => item.checked)
                                .map((ins, key) => {
                                  return (
                                    <React.Fragment key={key}>
                                      <Styled.InsuranceItem>
                                        <Text fontWeight="600">
                                          {ins.label}
                                        </Text>
                                        <Styled.CrossButton
                                          onClick={() => {
                                            innerDispatch({
                                              type: 'switchInsurance',
                                              payload: {
                                                id: ins.value,
                                                passenger: item.value,
                                              },
                                            });
                                          }}
                                        />
                                      </Styled.InsuranceItem>
                                      {'  '}
                                    </React.Fragment>
                                  );
                                })}
                            </Styled.InsuranceList>
                          </Styled.InsurancesPassenger>
                        ) : null;
                      })
                      .value()}
                    <Styled.GrayLine />
                  </Styled.PassengersListWrapper>
                )}
              </>
            )}
            <Textarea
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                errors.message && submitCount > 0 ? errors.message : undefined
              }
              name="message"
              marginTop={15}
              style={{ width: '100%', resize: 'none' }}
              rows={10}
              label="Текст обращения"
            />
            <Styled.DragAndDropFiles
              onChange={(files) => {
                setFiles(files);
              }}
            />
            <Styled.LastBlock>
              <div></div>
              <Styled.Submit>Отправить</Styled.Submit>
            </Styled.LastBlock>
          </Styled.Form>
        )}
      </Formik>
    </div>
  );
};

const ValueContainer = (props: any) => {
  return (
    <components.ValueContainer {...props}>
      <ContainerMultiList>{props.children}</ContainerMultiList>
    </components.ValueContainer>
  );
};

const MultiValueContainer = (props: any) => {
  return (
    <components.MultiValueContainer {...props}>
      <ContainerMultiValue>{props.children}</ContainerMultiValue>
      <Text size="big"> /</Text>
    </components.MultiValueContainer>
  );
};

export default CreateFormPopup;
