import { Api } from '@utils';
import _ from 'lodash';
import moment from 'moment';

import { normalizeTicketsList } from './normalizers';
import {
  FilterTrainDurationItem,
  GetCarsDto,
  GetCarsPayload,
  GetTicketsListDto,
  GetTrainRoutePayload,
  TrainDirectionType,
  TrainFilter,
  TrainFilterCarType,
  TrainFilterItem,
  TrainFilterTrainName,
  TrainRoute,
  TrainSearchPayload,
  TrainTimesItem,
} from './types';
import {
  RAILWAY_CAR_SERVICES,
  CAR_TYPES_TITLES,
} from '@modules/trainTickets/constants';
import {
  enrichmentCarsData,
  enrichmentTrainsData,
} from '@modules/trainTickets/utils';
import { GetTrainRouteDto } from '@modules/trainTickets/dto/GetTrainRouteDto';

const TRAIN_API_URL = process.env.REACT_APP_TRAIN_SERVICE_HOST as string;
const TRAIN_SEARCH_TRAINS = process.env.REACT_APP_TRAIN_SEARCH as string;
const REACT_APP_TRAIN_GET_CARS = process.env.REACT_APP_TRAIN_GET_CARS as string;
const REACT_APP_TRAIN_GET_ROUTE = process.env
  .REACT_APP_TRAIN_GET_ROUTE as string;

export const getTrainTicketsList = function (
  params: TrainSearchPayload,
): Promise<GetTicketsListDto> {
  let strSearchParams = `/${params.From}-${params.To}/${params.DepartureDate}/`;
  if (params.DepartureDateBack) {
    strSearchParams += `${params.To}-${params.From}/${params.DepartureDateBack}/`;
  }
  strSearchParams += `${params.Adults}-${params.Children}-${params.Infants}`;
  const getTicketsLink = Api.createFetchUrl(TRAIN_API_URL, TRAIN_SEARCH_TRAINS);
  const url = getTicketsLink(strSearchParams, { salesChannel: 'z' });
  return Api.request({
    url: url,
  })
    .then(Api.statuses)
    .then(Api.json)
    .then(normalizeTicketsList)
    .then((data) => enrichmentTrainsData(data));
};

export const getCars = function (params: GetCarsPayload): Promise<GetCarsDto> {
  const getCarsLink = Api.createFetchUrl(
    TRAIN_API_URL,
    REACT_APP_TRAIN_GET_CARS,
  );
  const queryParams = {
    From: params.From,
    To: params.To,
    DepartureDate: params.DepartureDate,
    CarType: params.CarType,
    TrainNumber: params.TrainNumber,
    Tariff: ['Full'],
    SalesChannel: 'z',
  };
  if (params.TariffType && params.TariffType?.length > 0) {
    queryParams.Tariff = [...queryParams.Tariff, ...[params.TariffType]];
  }
  const url = getCarsLink('', queryParams);

  return Api.request({
    url: url,
  })
    .then(Api.statuses)
    .then(Api.json)
    .then((data) => enrichmentCarsData(data, params.TrainNumber === '016М'));
};

export const getTrainRoute = function (
  params: GetTrainRoutePayload,
): Promise<GetTrainRouteDto> {
  const getTrainRouteLink = Api.createFetchUrl(
    TRAIN_API_URL,
    REACT_APP_TRAIN_GET_ROUTE,
  );
  const url = getTrainRouteLink('', {
    TrainNumber: params.TrainNumber,
    Origin: params.Origin,
    Destination: params.Destination,
    DepartureDate: params.DepartureDate,
    Provider: params.Provider,
  });
  return Api.request({
    url: url,
  })
    .then(Api.statuses)
    .then(Api.json)
    .then((data) => data);
};

export function createTrainFilter(data: GetTicketsListDto): TrainFilter[] {
  // TODO: проверка на существование маршрутов
  // if (data.trainsPerRoutes?.length === 0) return null;
  const filter = [
    createTrainFilterOnDestination(data.trainsPerRoutes[0], 'forward'),
  ];
  if (data.trainsPerRoutes?.length > 1) {
    filter.push(
      createTrainFilterOnDestination(data.trainsPerRoutes[1], 'backward'),
    );
  }

  return filter;
}

function createTrainFilterOnDestination(
  trainRoute: TrainRoute,
  type: TrainDirectionType,
): TrainFilter {
  let prices: number[] = [];
  const ticketPrices: number[][] = [];
  let trainDurations: FilterTrainDurationItem[] = [
    {
      from: {
        city: trainRoute?.stationFrom.city?.name_ru || '',
        label: trainRoute?.stationFrom.city?.name_ru || '',
        min: 0,
        max: 0,
        tickets: [],
      },
      to: {
        city: trainRoute.stationTo.city.name_ru,
        label: trainRoute.stationTo.city.name_ru,
        min: 0,
        max: 0,
        tickets: [],
      },
      max: 0,
      min: 0,
      tickets: [],
    },
  ];
  let carriers: TrainFilterItem[] = [];
  const carriersValues: { [key: string]: boolean } = {};
  const carriersTickets: string[][] = [];

  let trainTypes: TrainFilterItem[] = [];
  const trainTypesValues: { [key: string]: boolean } = {};
  const trainTypesTickets: string[][] = [];

  let trainNames: TrainFilterTrainName[] = [];
  const trainNamesValues: { [key: string]: boolean } = {};
  const trainNamesTickets: string[][] = [];

  let carTypes: TrainFilterCarType[] = [];
  const carTypesValues: { [key: string]: boolean } = {};
  const carTypesTickets: string[][] = [];

  let trainServices: TrainFilterItem[] = [];
  const trainServicesValues: { [key: string]: boolean } = {};
  const trainServicesTickets: string[][] = [];

  let discounts: TrainFilterItem[] = [];
  const discountsValues: { [key: string]: boolean } = {};
  const discountsTickets: string[][] = [];

  let departureDates: number[] = [];
  let arrivalDates: number[] = [];
  const timeArr: TrainTimesItem[] = [];

  timeArr.push({
    from: {
      city: trainRoute.stationFrom.city?.name_ru || '',
      label: trainRoute.stationFrom.city?.name_ru || '',
      min: 0,
      max: 0,
      tickets: [],
      values: [],
    },
    to: {
      city: trainRoute.stationTo.name_ru,
      label: trainRoute.stationTo.name_ru,
      min: 0,
      max: 0,
      tickets: [],
      values: [],
    },
  });

  const trains = trainRoute.trains;
  trains.forEach((train, key) => {
    trainDurations[0].tickets[key] = moment
      .duration(train.tripDuration)
      .asMinutes();

    if (!ticketPrices[key]) {
      ticketPrices[key] = [];
    }

    if (!carTypesTickets[key]) {
      carTypesTickets[key] = [];
    }

    if (!trainTypesTickets[key]) {
      trainTypesTickets[key] = [];
    }

    if (!trainNamesTickets[key]) {
      trainNamesTickets[key] = [];
      trainNamesTickets[key].push(train.trainName);
    }

    if (!trainServicesTickets[key]) {
      trainServicesTickets[key] = [];
      trainServicesTickets[key].push(...train.carServices);
    }

    if (!carriersTickets[key]) {
      carriersTickets[key] = [];
      carriersTickets[key].push(...train.carriers);
    }

    if (!discountsTickets[key]) {
      discountsTickets[key] = [];
    }

    timeArr[0].from.tickets.push(moment(train.localDepartureDate).unix());
    timeArr[0].to.tickets.push(moment(train.localArrivalDate).unix());

    !!train.carServices?.length &&
      trainServices.push(
        ...train.carServices.map((service) => {
          return {
            label: RAILWAY_CAR_SERVICES[service],
            value: service,
            checked: false,
          };
        }),
      );

    carriers.push(
      ...train.carriers.map((carrier) => {
        return {
          label: carrier,
          value: carrier,
          checked: false,
        };
      }),
    );

    !!train.trainName.length &&
      trainNames.push({
        label: train.trainName,
        value: train.trainName,
        checked: false,
        icon: 'todo_url',
      });

    departureDates.push(moment(train.localDepartureDate).unix());
    arrivalDates.push(moment(train.localArrivalDate).unix());

    if (train.isBranded) {
      trainTypes.push({
        label: 'Фирменный',
        value: 'Фирменный',
        checked: false,
      });
      trainTypesTickets[key].push('Фирменный');
    }
    if (train.hasTwoStoreyCars) {
      trainTypes.push({
        label: 'Двухэтажный',
        value: 'Двухэтажный',
        checked: false,
      });
      trainTypesTickets[key].push('Двухэтажный');
    }
    if (train.trainDescription === 'СК') {
      trainTypes.push({
        label: 'Скоростной',
        value: 'Скоростной',
        checked: false,
      });
      trainTypesTickets[key].push('Скоростной');
    }

    train.carGroups.forEach((carGroup) => {
      carTypesTickets[key].push(carGroup.carType);

      carTypes.push({
        value: carGroup.carType,
        label: CAR_TYPES_TITLES[carGroup.carType],
        checked: false,
      });

      prices.push(carGroup.minPrice.totalPrice);
      prices.push(carGroup.maxPrice.totalPrice);

      ticketPrices[key].push(carGroup.minPrice.totalPrice);
      ticketPrices[key].push(carGroup.maxPrice.totalPrice);

      discountsTickets[key].push(
        ...carGroup.discounts.map((discount) => discount.discountType + ''),
      );
      discountsTickets[key] = _.uniq(discountsTickets[key]);
      discounts.push(
        ...carGroup.discounts.map((discount) => {
          return {
            label: discount.description,
            value: discount.discountType + '',
            checked: false,
          };
        }),
      );
    });
  });

  trainDurations = trainDurations.map((duration) => {
    return {
      from: duration.from,
      to: duration.to,
      min: Math.min(...duration.tickets),
      max: Math.max(...duration.tickets),
      tickets: duration.tickets,
    };
  });

  trainServices = _.compact(
    _.uniqBy(trainServices, (service) => service.value),
  );
  carTypes = _.uniqBy(carTypes, (carType) => carType.value);
  prices = _.uniq(prices);
  const updatedPrices = ticketPrices.map((trainPrice) => {
    return [Math.min(...trainPrice), Math.max(...trainPrice)];
  });
  carriers = _.uniqBy(carriers, (carrier) => carrier.value);
  trainTypes = _.uniqBy(trainTypes, (trainType) => trainType.value);
  trainNames = _.uniqBy(trainNames, (trainName) => trainName.value);
  discounts = _.uniqBy(discounts, (discount) => discount.value);
  departureDates = _.uniq(departureDates);
  arrivalDates = _.uniq(arrivalDates);

  carTypes.forEach((carType) => {
    carTypesValues[carType.value] = false;
  });

  trainTypes.forEach((trainType) => {
    trainTypesValues[trainType.value] = false;
  });

  trainServices.forEach((trainService) => {
    trainServicesValues[trainService.value] = false;
  });

  carriers.forEach((carrier) => {
    carriersValues[carrier.value] = false;
  });

  trainNames.forEach((trainName) => {
    trainNamesValues[trainName.value] = false;
  });

  discounts.forEach((discount) => {
    discountsValues[discount.value] = false;
  });

  const times: TrainTimesItem[] = timeArr.map((el) => {
    const fromMax = Math.max(...el.from.tickets);
    const fromMin = Math.min(...el.from.tickets);
    const toMax = Math.max(...el.to.tickets);
    const toMin = Math.min(...el.to.tickets);
    return {
      ...el,
      from: {
        ...el.from,
        min: fromMin,
        max: fromMax,
        values: [fromMin, fromMax],
      },
      to: {
        ...el.to,
        min: toMin,
        max: toMax,
        values: [toMin, toMax],
      },
    };
  });
  //
  return {
    carTypes: carTypes,
    carTypesTickets: carTypesTickets,
    carriers: carriers,
    carriersTickets: carriersTickets,
    discounts: discounts,
    discountsTickets: discountsTickets,
    filterDestinationType: type,
    isFiltered: false,
    prices: {
      min: Math.min(...prices),
      max: Math.max(...prices),
      tickets: updatedPrices,
    },
    times: times,
    trainDurationsList: trainDurations,
    trainNames: trainNames,
    trainNamesTickets: trainNamesTickets,
    trainServices: trainServices,
    trainServicesTickets: trainServicesTickets,
    trainTypes: trainTypes,
    trainTypesTickets: trainTypesTickets,
    values: {
      arrivalDates: [Math.min(...arrivalDates), Math.max(...arrivalDates)],
      carTypes: carTypesValues,
      carriers: carriersValues,
      departureDates: [
        moment(Math.min(...departureDates)).minutes(),
        moment(Math.max(...departureDates)).minutes(),
      ],
      discounts: discountsValues,
      prices: [Math.min(...prices), Math.max(...prices)],
      trainNames: trainNamesValues,
      trainServices: trainServicesValues,
      trainTypes: trainTypesValues,
      times: [
        Math.min(...trainDurations[0].tickets),
        Math.max(...trainDurations[0].tickets),
      ],
      trainDurations: [
        Math.min(...trainDurations[0].tickets),
        Math.max(...trainDurations[0].tickets),
      ],
    },
    isDefault: {
      carTypes: true,
      carriers: true,
      discounts: true,
      prices: true,
      times: true,
      trainDurations: true,
      trainNames: true,
      trainServices: true,
      trainTypes: true,
    },
  };
}
