import { createSelector } from 'reselect';
import { ApplicationState } from '@modules/index';

const selectFlightSearch = (state: ApplicationState) =>
  state.mainReducer.flightSearch;

export const selectFlightSearchData = createSelector(
  [selectFlightSearch],
  (flightSearch) => ({
    flightSearch,
  }),
);
