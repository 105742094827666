import React from 'react';
import { TicketItemProps } from './types';

const Steps = React.createContext<TicketItemProps>(null as any);

export const useTicketProps = () => React.useContext(Steps);

export const TicketProvider: React.FC<
  TicketItemProps & React.PropsWithChildren
> = React.memo(
  ({ children, ...props }) => {
    return <Steps.Provider value={props}>{children}</Steps.Provider>;
  },
  (prevProps, nextProps) =>
    prevProps.id === nextProps.id &&
    prevProps._isMostCheap === nextProps._isMostCheap,
);
