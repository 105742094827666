import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';

interface Props {
  count: number;
  successCount: number;
}

const fillAnimation = keyframes`
    0% {
        width: 0;
    }
    100% {
        width: 100%;
    }
`;

const stripeAnimation = keyframes`
    0% {
        background-position: 0 0;
    }
    100% {
        background-position: 40px 40px;
    }
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  margin-bottom: 20px;
  position: sticky;
  top: 0;
  z-index: 10;
`;

const ProgressBarContainer = styled.div`
  width: 100%;
  background-color: #edf1fe;
  border-radius: 4px;
  overflow: hidden;
`;

const ProgressBarFill = styled.div<{ width: number }>`
  height: 4px;
  background-color: #4872f2;
  width: ${({ width }) => width}%;
  transition: width 0.5s ease-in-out;
  background-image: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.2) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.2) 50%,
    rgba(255, 255, 255, 0.2) 75%,
    transparent 75%,
    transparent
  );
  background-size: 40px 40px;
  animation: ${stripeAnimation} 1s linear infinite;
`;

const LinearProgressBar: React.FC<Props> = ({ count, successCount }) => {
  const [width, setWidth] = useState(0);

  useEffect(() => {
    const percentage = (successCount / count) * 100;
    setWidth(percentage);
  }, [count, successCount]);

  return (
    <Container>
      <ProgressBarContainer>
        <ProgressBarFill width={width} />
      </ProgressBarContainer>
    </Container>
  );
};

export default LinearProgressBar;
