import {
  FLIGHT_TYPES,
  FlightType,
} from '@components/booking/FlightDetail/types';

export function extractFirstNumber(input: string): number | null {
  const match = input.match(/\d+/);
  return match ? parseInt(match[0], 10) : null;
}

export const getFlightTypeTitle = (flightType: FlightType, isShort = false) => {
  switch (flightType) {
    case FLIGHT_TYPES.REGULAR:
      return isShort ? 'Регулярный' : 'Регулярный рейс';
    case FLIGHT_TYPES.CHARTER:
      return isShort ? 'Чартер' : 'Чартерный рейс';
    case FLIGHT_TYPES.LOWCOSTER:
      return 'Лоукостер';
    default:
      return isShort ? 'Регулярный' : 'Регулярный рейс';
  }
};
