import React from 'react';
import styled from 'styled-components';
import Text from '@components/ui/Typography';
import aircraftIcon from '@assets/media/flight-search/flightNumberIcon.svg';
import luggageIcon from '@assets/media/flight-search/luggage-round-icon.svg';
import noLuggageIcon from '@assets/media/shared/no-baggage.svg';
import { useMediaQuery } from 'react-responsive';
import infoIcon from '@assets/media/info-icon.svg';
import Tippy from '@tippyjs/react';

interface Props {
  title: string;
  baseItems: string[];
  additionalItem?: string;
  type: 'aircraft' | 'luggage' | 'info';
  noBaggage?: boolean;
  content?: string;
}

interface IconProps {
  isInfo?: boolean;
}

const Row = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
`;

const Icon = styled.img<IconProps>`
  margin-right: 5px;
  width: 25px;
  height: 25px;
`;

const IconInfo = styled.img<IconProps>`
  margin-right: 5px;
  width: 18px;
  height: 18px;
  margin-left: 16px;
`;

const MutedText = styled(Text)`
  color: #737373;
`;

/**
 * Компонент для отображения строки с информацией о самолете или багаже
 * @param props
 * @constructor
 */
const AviaInfoRow: React.FC<Props> = (props) => {
  const { title, baseItems, additionalItem, type, noBaggage } = props;
  const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <Row>
      {type !== 'info' && (
        <Icon
          src={
            type === 'aircraft'
              ? aircraftIcon
              : noBaggage
                ? noLuggageIcon
                : luggageIcon
          }
        />
      )}
      {type === 'info' && (
        <Tippy content={props.content} theme={'light'}>
          <IconInfo src={infoIcon} />
        </Tippy>
      )}

      <MutedText size={isMobile ? 'small' : 'normal'}>
        {title ? `${title}:\u00A0` : ''}
      </MutedText>
      <Text size={isMobile ? 'small' : 'normal'}>
        {baseItems.join(', ')} {additionalItem ? ` • ${additionalItem}` : ''}
      </Text>
    </Row>
  );
};

export default AviaInfoRow;
