import {
  AirCheckAvailabilityDto,
  BookTicketPayload,
  GetAddictionalServicesDto,
  BookTicketDto,
} from './types';
import { Api, HttpError } from '@utils';
import { trackPromise } from 'react-promise-tracker';

import {
  checkAvailabilityNormalizer,
  normalizeGetInsuranceCalculationResponse,
} from './utils';
import { AVIA_SERVICE_HOST } from '@modules/constants';
import { handleStatuses } from '@app/utils/fetch';

const { CONTENT_TYPE, STRATEGY_TYPES } = Api;
const URI = process.env.REACT_APP_FLIGHTS_API as string;

export const BOOKING_NOT_AVAILABLE = 431;

const createAviaFetchUrl = Api.createFetchUrl(AVIA_SERVICE_HOST, URI);

const createInsuranceFetchUrl = Api.createFetchUrl(
  process.env.REACT_APP_INSURANCES_SERVICE_HOST as string,
  process.env.REACT_APP_INSURANCES_API as string,
);
// const createSmsNotificationService = Api.createFetchUrl(process.env.REACT_APP_ORDER_SERVICE_HOST as string,
//   process.env.REACT_APP_SMS_NOTIFICATIONS as string);

const LAUNDBRAUM_UUID = process.env.REACT_APP_LAUNDBRAUM_UUID as string;

const END_POINTS = {
  checkAvailablity: process.env.REACT_APP_CHECK_AVAILABILITY as string,
  checkAvailablityv5: process.env.REACT_APP_CHECK_AVAILABILITY_V5 as string,
  book: process.env.REACT_APP_BOOKING as string,
  bookV2: process.env.REACT_APP_BOOKING_V2 as string,
  setSmsNotifications: process.env.REACT_APP_SMS_NOTIFICATIONS as string,
};

/**
 * action: @booking/selectFlightRequest
 * @param id id search result
 * @param index index of selected group
 * @param indexesOfFlightsInGroups index of selected flights in selected group
 * @param pluginToken plugin token
 */
export function checkAvailablity(
  id: string,
  index: number,
  indexesOfFlightsInGroups: number[],
  pluginToken: string,
) {
  const headers = {
    'Content-Type': Api.CONTENT_TYPE.JSON,
    'X-Account': pluginToken || sessionStorage.getItem('logSessionId') || '',
  };
  const isLaundbraumUUID =
    sessionStorage.getItem('flightSearchExperiment') === LAUNDBRAUM_UUID;

  return fetch(
    createAviaFetchUrl(
      `${isLaundbraumUUID ? END_POINTS.checkAvailablityv5 : END_POINTS.checkAvailablity}/${id}/${index}/${indexesOfFlightsInGroups.join(',')}`,
    ),
    { headers },
  )
    .then(Api.statuses)
    .then((response) => Api.json(response) as Promise<AirCheckAvailabilityDto>)
    .then((data) => {
      if (!data.fares || !data.fares[0]?.isAvailable) {
        return data;
      }
      return data;
    })
    .then(checkAvailabilityNormalizer);
}

/**
 * action: @booking/create
 * @param data
 */
export function bookTicket(data: BookTicketPayload): Promise<BookTicketDto> {
  const isLaundbraumUUID =
    sessionStorage.getItem('flightSearchExperiment') === LAUNDBRAUM_UUID;

  if (data.pluginToken !== '') {
    return Api.request(
      {
        url: createAviaFetchUrl(
          isLaundbraumUUID ? END_POINTS.bookV2 : END_POINTS.book,
        ),
        method: 'POST',
        body: JSON.stringify({
          ...data,
          PromoCode: 'z',
          PaymentType: 'CASH',
        }),

        headers: {
          'Content-Type': CONTENT_TYPE.JSON,
          'X-Account': data.pluginToken,
        },
      },
      STRATEGY_TYPES.AUTH,
    )
      .then(Api.statuses)
      .then(Api.jsonBooking);
  } else
    return Api.request(
      {
        url: createAviaFetchUrl(
          isLaundbraumUUID ? END_POINTS.bookV2 : END_POINTS.book,
        ),
        method: 'POST',
        body: JSON.stringify({
          ...data,
          PromoCode: 'z',
          PaymentType: 'CASH',
        }),

        headers: {
          'Content-Type': CONTENT_TYPE.JSON,
        },
      },
      STRATEGY_TYPES.AUTH,
    )
      .then(Api.statuses)
      .then(Api.jsonBooking);
}

/**
 * action: @booking/create
 * @param data
 */
export function getInsurances(
  calculationId: string,
  isCharter: boolean,
  pluginToken: string,
): Promise<ReturnType<typeof normalizeGetInsuranceCalculationResponse>> {
  if (pluginToken !== '') {
    return Api.request({
      url: `${process.env.REACT_APP_INSURANCES_GET}?calculationId=${calculationId}&isCharter=${isCharter}`,
      method: 'GET',
      headers: {
        'Content-Type': Api.CONTENT_TYPE.JSON,
        'X-Account': pluginToken,
      },
    })
      .then(Api.statuses)
      .then(Api.json)
      .then(normalizeGetInsuranceCalculationResponse);
  } else
    return Api.request({
      url: `${process.env.REACT_APP_INSURANCES_GET}?calculationId=${calculationId}&isCharter=${isCharter}`,
      method: 'GET',
      headers: {
        'Content-Type': Api.CONTENT_TYPE.JSON,
      },
    })
      .then(Api.statuses)
      .then(Api.json)
      .then(normalizeGetInsuranceCalculationResponse);
}

export const getAddictionalServices = (
  orderId: string,
  pluginToken: string,
  isCharter?: boolean,
): Promise<GetAddictionalServicesDto> => {
  if (pluginToken !== '') {
    return Api.request({
      url: `${process.env.REACT_APP_GET_ADDICTIONAL_SERVICES}?orderId=${orderId}&isCharter=${isCharter}`,
      method: 'GET',
      headers: {
        'Content-Type': CONTENT_TYPE.JSON,
        'X-Account': pluginToken,
      },
    })
      .then(Api.statuses)
      .then(Api.json);
  } else
    return Api.request({
      url: `${process.env.REACT_APP_GET_ADDICTIONAL_SERVICES}?orderId=${orderId}&isCharter=${isCharter}`,
      method: 'GET',
      headers: {
        'Content-Type': CONTENT_TYPE.JSON,
      },
    })
      .then(Api.statuses)
      .then(Api.json);
};

export const getAgentOrderBill = (orderId?: string, token?: any): any =>
  trackPromise(
    Api.request(
      {
        url: `${process.env.REACT_APP_ORDER_SERVICE_HOST}/Order/get-agent-bill-by-order-id?orderId=${orderId}`,
        method: 'GET',
        headers: {
          'Content-Type': CONTENT_TYPE.JSON,
          Authorization: `Bearer ${token}`,
        },
      },
      'AUTH',
    )
      .then(handleStatuses())
      .then((data) => {
        return data.blob();
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'file.pdf';
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove(); //afterwards we remove the element again
      }),
  );

export const issueTicketByCredit = (orderId?: string, token?: any): any =>
  trackPromise(
    Api.request(
      {
        url: `${process.env.REACT_APP_ORDER_SERVICE_HOST3}/order${process.env.REACT_APP_ISSUE_TICKET_BY_CREDIT}`,
        method: 'POST',
        body: JSON.stringify({ orderId: orderId }),
        headers: {
          'Content-Type': CONTENT_TYPE.JSON,
          Authorization: `Bearer ${token}`,
        },
      },
      'AUTH',
    ),
  );

export const getAgentOrderBillWithoutTrackPromise = (
  orderId?: string,
  token?: any,
): any =>
  Api.request(
    {
      url: `${process.env.REACT_APP_ORDER_SERVICE_HOST}/Order/get-agent-bill-by-order-id?orderId=${orderId}`,
      method: 'GET',
      headers: {
        'Content-Type': CONTENT_TYPE.JSON,
        Authorization: `Bearer ${token}`,
      },
    },
    'AUTH',
  )
    .then(Api.statuses)
    .then((data) => {
      return data.blob();
    })
    .then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'file.pdf';
      document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
      a.click();
      a.remove(); //afterwards we remove the element again
    });

export function getAgentBillBySamoId(
  samoNumber: number | string,
  token: string | null,
) {
  return Api.request(
    {
      url: `${process.env.REACT_APP_ORDER_SERVICE_HOST}/Order/get-agent-bill-by-samo-number?samoNumber=${samoNumber}`,

      method: 'GET',
      headers: {
        'Content-Type': CONTENT_TYPE.JSON,
        Authorization: `Bearer ${token}`,
      },
    },
    'AUTH',
  )
    .then(handleStatuses())
    .then((data) => {
      return data.blob();
    })
    .then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'file.pdf';
      document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
      a.click();
      a.remove(); //afterwards we remove the element again
    });
}

export function getAgentBillByTteNumber(
  samoNumber: number | string,
  token: string | null,
) {
  return Api.request(
    {
      url: `${process.env.REACT_APP_ORDER_SERVICE_HOST}/Order/get-agent-bill-by-tte-number?tteNumber=${samoNumber}`,

      method: 'GET',
      headers: {
        'Content-Type': CONTENT_TYPE.JSON,
        Authorization: `Bearer ${token}`,
      },
    },
    'AUTH',
  )
    .then(handleStatuses())
    .then((data) => {
      return data.blob();
    })
    .then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'file.pdf';
      document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
      a.click();
      a.remove(); //afterwards we remove the element again
    });
}

export function setInsuranceAgreement(
  payload: any,
): Promise<ReturnType<typeof normalizeGetInsuranceCalculationResponse>> {
  return Api.request({
    url: `${process.env.REACT_APP_ORDER_SERVICE_HOST}/insurance/set-insurance-agreement`,

    method: 'POST',
    body: JSON.stringify(payload),
    headers: { 'Content-Type': Api.CONTENT_TYPE.JSON },
  })
    .then(Api.statuses)
    .then(Api.json);
}
