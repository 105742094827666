import { Action } from 'redux-act';
import _ from 'lodash';
import {
  select,
  call,
  put,
  all,
  takeLatest,
  spawn,
  take,
  actionChannel,
} from 'typed-redux-saga';
import * as FlightSearchManager from './Manager';
import {
  Airline,
  FlightListItems,
  FlightSearchRequestStatus,
  Item,
} from './types';
import { simpleSearchUpdateState, dynamicSearch } from '../simpleSearch';
import moment, { HTML5_FMT } from 'moment';
import {
  setFlightSearchRequestState,
  setFlightSearchData,
  setTimeOneWayFilter,
  setTransferDurationFilter,
  setPricesFilter,
  setAirportFilter,
  setAirlineFilter,
  setFlightTypeFilter,
  resetFilter,
  runFilter,
  setTransferFilter,
  setTimeFilter,
  setBaggageFilter,
  getNext,
  setList,
  purifySearchState,
  promotionsRequest,
  promotionsSuccess,
  promotionsFailure,
  setConnectionFilter,
  setAirGdsFilter,
  getGdsModulesHandler,
  getGdsModulesSuccess,
  getGdsModulesFailure,
  setGdsCount,
  setGdsCountWithAnswer,
  setDynamicFlightSearchRequestState,
  purifyList,
} from './duck';

import { Helper } from '@utils';
import {
  connectionFilterStatus,
  getPluginToken,
  getTicketList,
} from './selectors';
import { ApplicationState } from '@modules/index';
import { getUserDataRequestWorker, getUserDataState } from '@modules/user';
import {
  TTE_CACHE_USER,
  TTE_CACHE_USER_B2C,
  TTE_CACHE_USER_B2C_TEST,
  TTE_KZ_USER,
} from '@modules/user/constants';
import {
  createAviaFilter,
  searchOneWayDirectionByGds,
  searchWithReturnByGds,
} from './Manager';

function createMomentDate(str?: string | null) {
  if (!str) return null;
  return moment(str, 'DDMMYY');
}

function* watchGdsCountChannel() {
  const chan = yield* actionChannel('INCREMENT_GDS_COUNT');
  while (true) {
    yield* take(chan);
    const gdsCountWithAnswer = yield* select(
      (state: ApplicationState) =>
        state.mainReducer.flightSearch.dynamicSearch.gdsWithAnswer,
    );
    yield* put(setGdsCountWithAnswer(gdsCountWithAnswer + 1));
  }
}

function* fetchGdsData(
  gds: any,
  requestWithSamo: any,
  backwardDate: string,
  connectionStatus: boolean,
) {
  const response: any = backwardDate
    ? yield* call(searchWithReturnByGds, {
        ...requestWithSamo,
        backwardDate: backwardDate,
        gds: gds,
      })
    : yield* call(searchOneWayDirectionByGds, {
        ...requestWithSamo,
        gds: gds,
      });
  yield* put({ type: 'INCREMENT_GDS_COUNT' });

  if (response?.data && response.data.length > 0) {
    let updatedItems: FlightListItems = [];
    let updatedNotFilteredItems: FlightListItems = [];
    let updatedData: Item[] = [];
    let updatedReferenceAirlines: Airline[] = [];
    const currentFlightSearch = yield* select(
      (state: ApplicationState) => state.mainReducer.flightSearch,
    );
    const currentData = currentFlightSearch.data || [];
    const currentItems = currentFlightSearch.flightsList.items || [];
    const currentNotFilteredItems =
      currentFlightSearch.flightsList.notFilteredItems || [];
    const currentReferenceAirlines =
      currentFlightSearch.references?.Airlines || [];
    if (!currentItems || currentItems?.length == 0) {
      updatedItems = [...response.flightsList.items];
      updatedNotFilteredItems = [...response.flightsList.notFilteredItems];
      updatedData = [...response.data];
      updatedReferenceAirlines = [...response.references.Airlines];
    } else {
      updatedItems = [...currentItems, ...response.flightsList.items];
      updatedNotFilteredItems = [
        ...currentNotFilteredItems,
        ...response.flightsList.notFilteredItems,
      ];
      updatedData = [...currentData, ...response.data];
      updatedReferenceAirlines = _.uniqBy(
        [...currentReferenceAirlines, ...response.references.Airlines],
        'code',
      );
      updatedItems.sort((a: any, b: any) => {
        return a.prices[0] - b.prices[0];
      });
      updatedNotFilteredItems.sort((a: any, b: any) => {
        return a.prices[0] - b.prices[0];
      });
      updatedData.sort((a: any, b: any) => {
        return a.price.total.amount - b.price.total.amount;
      });
    }
    const updatedResponse = {
      ...response,
      data: updatedData,
      ...{
        flightsList: {
          items: updatedItems,
          notFilteredItems: updatedNotFilteredItems,
        },
      },
      ...{
        references: {
          Airlines: updatedReferenceAirlines,
        },
      },
    };
    updatedResponse.filter = createAviaFilter(updatedResponse);

    if (!connectionStatus) {
      yield* put(setFlightSearchData(updatedResponse));
      yield* put(
        setList({
          items: [...updatedItems],
          visibleItems: [...updatedItems].slice(0, 10),
          page: 1,
          pageCount: Math.ceil(updatedItems.length / 10),
        }),
      );
    } else {
      yield* put(setFlightSearchData(updatedResponse));
      const filteredData = updatedResponse.flightsList.items.filter(
        (x: any) => x.hasConnectingFlights === false,
      );

      yield* put(
        setList({
          items: [...filteredData],
          visibleItems: [...filteredData].slice(0, 10),
          page: 1,
          pageCount: Math.ceil(filteredData / 10),
        }),
      );
      yield* put(setConnectionFilter(false));
    }

    yield* put(
      setFlightSearchRequestState({
        status: FlightSearchRequestStatus.success,
      }),
    );
  }
  return response;
}

export function* dynamicFlightSearchSaga(
  action: Action<{ url: string; saleChannel?: string }>,
) {
  yield* put(
    setFlightSearchRequestState({ status: FlightSearchRequestStatus.loading }),
  );
  yield* put(
    setDynamicFlightSearchRequestState({
      status: FlightSearchRequestStatus.loading,
    }),
  );
  yield* call(gdsModulesSaga);
  const gdsList = yield* select(
    (state: ApplicationState) => state.mainReducer.gdsModules,
  );
  const connectionStatus = yield* select(connectionFilterStatus);
  yield* call(getUserDataRequestWorker);
  const parsedParams = Helper.parseSearchParams(action.payload.url);
  const pluginToken = yield* select(getPluginToken);
  const user = yield* select(getUserDataState);
  const isCacheTestUser =
    user?.email === TTE_CACHE_USER || user?.email === TTE_CACHE_USER_B2C;
  const isKZTestUser = user?.email === TTE_KZ_USER;
  const isCacheUserWithFalse = user?.email === TTE_CACHE_USER_B2C_TEST;

  let saleChannel = action.payload?.saleChannel;
  if (isKZTestUser) {
    saleChannel = 'gdskz';
  }
  if (
    user?.email === TTE_CACHE_USER_B2C ||
    user?.email === TTE_CACHE_USER_B2C_TEST
  ) {
    saleChannel = 'aviasales';
  }

  const paramsForRequest = {
    origin: parsedParams.origin,
    destination: parsedParams.destination,
    passengers: {
      adults: parsedParams.adults,
      children: parsedParams.children,
      infants: parsedParams.infants,
    },
    forwardDate: createMomentDate(parsedParams.forwardDate)?.format(
      HTML5_FMT.DATE,
    ),
    backwardDate: createMomentDate(parsedParams.backwardDate)?.format(
      HTML5_FMT.DATE,
    ),
    category: parsedParams.flightClass,
    saleChannel: saleChannel,
    pluginToken: pluginToken,
  };

  yield* spawn(simpleSearchUpdateState, parsedParams);
  yield* call(getUserDataRequestWorker);

  const requestWithSamo: any = paramsForRequest;

  if (user && user.agentSamoCode !== null) {
    requestWithSamo['agentSamoCode'] = user.agentSamoCode;
  }
  if (isCacheTestUser) {
    requestWithSamo['cache'] = true;
  }
  if (isCacheUserWithFalse) {
    requestWithSamo['cache'] = false;
  }

  try {
    window.localStorage.setItem('origin', parsedParams.origin);
    window.localStorage.setItem('destination', parsedParams.destination);

    if (gdsList) {
      yield* all(
        gdsList.map((gds) =>
          call(
            { context: null, fn: fetchGdsData },
            gds,
            requestWithSamo,
            paramsForRequest.backwardDate || '',
            connectionStatus,
          ),
        ),
      );
    }
  } catch (error) {
    console.error(error);
    yield* put(
      setFlightSearchRequestState({
        status: FlightSearchRequestStatus.failure,
      }),
    );
    yield* put(
      setDynamicFlightSearchRequestState({
        status: FlightSearchRequestStatus.success,
      }),
    );
  } finally {
    yield* put(
      setFlightSearchRequestState({
        status: FlightSearchRequestStatus.success,
      }),
    );
    yield* put(
      setDynamicFlightSearchRequestState({
        status: FlightSearchRequestStatus.success,
      }),
    );
  }
}

export function* runFilterSaga() {
  yield* put(runFilter());
  const {
    mainReducer: {
      flightSearch: {
        flightsList: { items },
      },
    },
  } = yield* select((state: ApplicationState) => state);
  yield* put(
    setList({
      items: [...items],
      visibleItems: [...items].slice(0, 10),
      page: 1,
      pageCount: Math.ceil(items.length / 10),
    }),
  );
}

function* getNextWorker() {
  const data = yield* select(getTicketList);
  if (data.page === data.pageCount) {
    return;
  }
  const visibleItems = [...data.items].slice(0, (data.page + 1) * 10);

  yield* put(
    setList({
      ...data,
      visibleItems,
      page: data.page + 1,
    }),
  );
}

function* promotionsRequestFlow() {
  try {
    const response = yield* call(FlightSearchManager.getPromitions);
    yield* put(promotionsSuccess(response));
  } catch (e) {
    yield* put(promotionsFailure());
  }
}

function* gdsModulesSaga() {
  try {
    const gdsModules = yield* call(FlightSearchManager.getGdsModules);
    yield* put(setGdsCount(gdsModules.data.length));
    yield* put(setGdsCountWithAnswer(0));
    yield* put(getGdsModulesSuccess(gdsModules));
  } catch (e) {
    yield* put(getGdsModulesFailure());
  }
}

function* purifyStateSaga() {
  yield* put(purifySearchState());
  yield* put(purifyList());
}

export default function* rootSaga() {
  yield* all([
    call(watchGdsCountChannel),
    takeLatest(dynamicSearch.getType(), function* (action: any) {
      yield* call(purifyStateSaga);
      yield* call(dynamicFlightSearchSaga, action);
    }),
    takeLatest(promotionsRequest.getType(), promotionsRequestFlow),
    takeLatest(
      [
        resetFilter,
        setTimeOneWayFilter,
        setTransferDurationFilter,
        setPricesFilter,
        setAirportFilter,
        setAirlineFilter,
        setTransferFilter,
        setTimeFilter,
        setBaggageFilter,
        setFlightTypeFilter,
        setAirGdsFilter,
        setConnectionFilter,
      ],
      runFilterSaga,
    ),
    takeLatest(getNext.getType(), getNextWorker),
    takeLatest(getGdsModulesHandler.getType(), gdsModulesSaga),
  ]);
}
