export const AVIA_SERVICE_HOST = process.env.REACT_APP_AVIA_SERVICE_HOST;
export const ORDER_SERVICE_HOST3 = process.env.REACT_APP_ORDER_SERVICE_HOST3;
export const ORDER_SERVICE_HOST = process.env.REACT_APP_ORDER_SERVICE_HOST;
export const NEW_ORDER_SERVICE_HOST =
  process.env.REACT_APP_NEW_ORDER_SERVICE_HOST;
export const COUNTRIES_SERVICE_HOST =
  process.env.REACT_APP_COUNTRIES_SERVICE_HOST;
export const SOCKET_ORDER_UPDATE = process.env.REACT_APP_SOCKET_ORDER_UPDATE;
export const AVIA_CITY_BY_CODE_GET =
  process.env.REACT_APP_AVIA_CITY_BY_CODE_GET;
export const MODULES_SERVICE_HOST =
  process.env.REACT_APP_GET_MODULES_SERVICE_HOST;
export const LOG_SERVICE_HOST = process.env.REACT_APP_LOG_SERVICE_HOST;
export const LOWCOSTERS_WITH_CHILD_ATTR = ['5W', 'W6', 'FR', 'VY', 'V7', 'KC'];
