import React, { ChangeEvent, ReactElement } from 'react';
import { FieldProps, PassengersFormData } from './interfaces';
import { Checkbox, Field, Input, Radio, Select, FieldDesc } from './styles';
import { FormikErrors, FormikTouched, FormikValues } from 'formik';
import { checkDisabledStatus } from '@components/bus/Booking/controller';
import CountriesSelect from '@components/booking/CountriesSelect';
import styled from 'styled-components';
import { CountryListItemType } from '@components/booking/PassengersBlock/types';
import InputMask from 'react-input-mask';
import { differenceInYears, isValid, parse } from 'date-fns';

const Label = styled.label`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #737373;
  margin-bottom: 5px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: contents;
`;

const Countries = styled.div`
  display: flex;
  flex-direction: column;

  & > div {
    &:last-child {
      display: none !important;
    }
  }
`;

interface RenderFieldsProps {
  documentTypes: Array<{ value: string; label: string }>;
  setFieldTouched: (
    field: string,
    isTouched?: boolean,
    shouldValidate?: boolean,
  ) => Promise<void | FormikErrors<FormikValues>>;
  setFieldValue: (key: string, value: any) => void;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
  errors: FormikErrors<FormikValues>;
  touched: FormikTouched<FormikValues>;
  values: FormikValues;
  key: string;
}

export const renderField =
  ({
    documentTypes,
    setFieldTouched,
    setFieldValue,
    handleChange,
    values,
    key: baseKey,
    errors,
    touched,
  }: RenderFieldsProps) =>
  ({
    type,
    value,
    label,
    placeholder,
    key,
    options,
    description,
    tooltipText,
  }: FieldProps): ReactElement => {
    const field = errors[baseKey as keyof typeof errors] as Record<string, any>;
    const fieldTouched: boolean =
      (touched[baseKey as keyof typeof errors] as Record<string, any>)?.[key] ||
      false;
    const errorText = field ? field[key] : undefined;
    const error = errorText && fieldTouched ? errorText : undefined;

    const findSelectOption =
      (value: string) => (field: { value: string; label: string }) =>
        field.value === value;

    switch (type) {
      case 'input':
        return (
          <div>
            <Input
              key={`${baseKey}.${key}`}
              type="text"
              value={values[baseKey][key]}
              name={`${baseKey}.${key}`}
              disabled={checkDisabledStatus(key, baseKey, values)}
              label={label}
              placeholder={placeholder}
              onChange={handleChange}
              onBlur={() => {
                setFieldTouched(`${baseKey}.${key}`);
              }}
              error={error}
            />
            {description && <FieldDesc>{description}</FieldDesc>}
          </div>
        );
      case 'checkbox':
        return (
          <Checkbox
            key={`${baseKey}.${key}`}
            type="squar"
            label={label}
            checked={values[baseKey][key]}
            name={`${baseKey}.${key}`}
            onChange={(event) => {
              setFieldValue(`${baseKey}.${key}`, event.target.checked);
            }}
          />
        );
      case 'select':
        return (
          <Select
            tooltipText={tooltipText}
            key={`${baseKey}.${key}`}
            label={label}
            options={key === 'doctype' ? documentTypes : options}
            placeholder={placeholder}
            name={`${baseKey}.${key}`}
            value={
              key === 'doctype'
                ? documentTypes.find(findSelectOption(values[baseKey][key]))
                : options !== undefined
                  ? options.find(findSelectOption(values[baseKey][key]))
                  : undefined
            }
            onChange={({ value }: any) => {
              setFieldValue(`${baseKey}.${key}`, value);
              setTimeout(() => {
                setFieldTouched(`${baseKey}.${key}`);
              }, 100);
            }}
            onBlur={() => {
              setFieldTouched(`${baseKey}.${key}`);
            }}
            error={error}
          />
        );
      case 'countriesSelect':
        return (
          <div>
            <Countries key={`${baseKey}.${key}`}>
              <Label>Гражданство</Label>

              <CountriesSelect
                searchType={'bus'}
                error={error}
                citizenship={{ value: 'RU', label: 'Россия' }}
                handleCitizenshipChange={(option: any) =>
                  setFieldValue(`${baseKey}.${key}`, option?.value)
                }
                countriesList={options as CountryListItemType[]}
                onBlur={() => {
                  setFieldTouched(`${baseKey}.${key}`);
                }}
              />
            </Countries>
            {description && <FieldDesc>{description}</FieldDesc>}
          </div>
        );
      case 'radio':
        return (
          <Radio
            key={`${baseKey}.${key}`}
            name={`${baseKey}.genger`}
            onChange={() => {
              setFieldValue(`${baseKey}.gender`, key);
            }}
            value={value}
            checked={values[baseKey].gender === key}
            label={label}
          />
        );
      case 'datePicker':
        // eslint-disable-next-line no-case-declarations
        const disabledRulesForIssueDate = [
          undefined,
          '',
          'BirthCertificate',
          'RussianPassport',
        ];
        // eslint-disable-next-line no-case-declarations
        const isIssueDateDisable = disabledRulesForIssueDate.includes(
          values[baseKey]['doctype'],
        );

        return (
          <InputMask
            mask="99.99.9999"
            onChange={(event) => {
              setFieldValue(`${baseKey}.${key}`, event.target.value);

              const parsedDate = parse(
                event.target.value,
                'dd.MM.yyyy',
                new Date(),
              );

              if (key === 'dob' && isValid(parsedDate)) {
                const age = differenceInYears(new Date(), parsedDate);

                setFieldValue(
                  `${baseKey}.ageType`,
                  age >= 12 ? 'Взрослый' : 'Ребенок',
                );
              }
            }}
            onBlur={() => {
              setFieldTouched(`${baseKey}.${key}`);
            }}
            value={values[baseKey][key]}
            alwaysShowMask={false}
            disabled={key === 'issueDate' && isIssueDateDisable}
          >
            <Input
              label={label}
              name={`${baseKey}.${key}`}
              type="tel"
              error={error}
              inputMode="numeric"
              placeholder="дд.мм.гггг"
            />
          </InputMask>
        );
      default:
        return <div key={`${baseKey}.${key}`} />;
    }
  };

export const renderFields =
  ({
    setFieldTouched,
    setFieldValue,
    handleChange,
    values,
    key: baseKey,
    errors,
    touched,
    documentTypes,
  }: RenderFieldsProps) =>
  ({ width, key, fields, isFullWidth }: PassengersFormData): ReactElement => {
    const handleTrimmedChange = (event: ChangeEvent<HTMLInputElement>) => {
      event.target.value = event.target.value.trim();
      handleChange(event);
    };

    return (
      <Field key={key} width={width} isFullWidth={isFullWidth}>
        {fields.map(
          renderField({
            documentTypes,
            setFieldTouched,
            setFieldValue,
            handleChange: handleTrimmedChange,
            values,
            key: baseKey,
            errors,
            touched,
          }),
        )}
      </Field>
    );
  };
