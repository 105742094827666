import React from 'react';
import styled from 'styled-components';
import { InfoBlock, Separator } from '@components/common';
import { InsurancesProps } from './types';
import Text, { Link } from '@components/ui/Typography';
import Position from './Position';
import { getTotalSum, getProductCompanies } from './utils';
import { Helper } from '@utils';
import AvailableOffersList from '@components/booking/Insurance/Slider';
import { useSelector, useDispatch } from 'react-redux';
import { addInsurances, deleteInsurances } from '@modules/orders';
import { ApplicationState } from '@modules/index';
import alphaStrah from '@assets/media/insurances/alphaStrah.svg';
import { Checkbox } from '@components/ui/form';
import { postSessionLogHandler } from '@modules/logs/duck';
import { UserActionTypes } from '@modules/logs/types';
import { LOGGING_ACTION_IDS } from '@modules/logs/constants';

interface LinkProps {
  isDetail?: boolean;
}

const Head = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;

  @media (max-width: 767px) {
    margin-bottom: 0;
  }
`;

const Wrapper = styled(InfoBlock)<LinkProps>`
  @media (max-width: 767px) {
    border: none;
    box-shadow: none;
    margin-top: 10px;
    width: 100%;
    padding: ${({ isDetail }) => (isDetail ? '14px' : '0')};
  }

  @media (min-width: 767px) {
    width: 100%;
    margin-left: 0;
    border: ${({ isDetail }) => (isDetail ? '1px solid #DCDCDC' : 'none')};
    box-shadow: none;
    padding: ${({ isDetail }) => (isDetail ? '14px' : '0')};
  }
`;

const Title = styled(Text)`
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  @media (max-width: 767px) {
    font-size: 14px;
    line-height: 16px;
  }
`;

const Subtitle = styled(Text)`
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
`;

const List = styled.div`
  & > div {
    padding-top: 5px;
    padding-bottom: 20px;
    border-bottom: 1px solid #dcdcdc;
  }

  & > :last-child {
    border-bottom: none;
  }

  @media (max-width: 767px) {
    & > div {
      padding: 15px 0;
    }

    margin-bottom: 5px;
  }
`;

const TotalSum = styled(Text)`
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;

  & > strong {
    color: #4872f2;
    font-weight: 600;
  }

  @media (max-width: 767px) {
    font-size: 14px;
    line-height: 18px;
  }
`;

const IconsContainer = styled.div`
  display: flex;
  align-items: center;

  & > img {
    margin-right: 10px;

    @media (max-width: 767px) {
      height: 20px;
    }
  }

  & > :last-child {
    margin-right: 0;
  }
`;

const NotAddedInsurancesOffers = styled.div`
  margin-top: 50px;

  ${Title} {
    margin-bottom: 15px;
    display: block;
  }

  ${Subtitle} {
    margin-bottom: 20px;
    display: block;
  }

  ${TotalSum} {
    margin-top: 30px;
    display: block;
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const AlphaStrah = styled.img.attrs({ src: alphaStrah })`
  margin-left: 5px;
  margin-bottom: 15px;
`;

const CheckboxWrapper = styled.div`
  display: flex;
  margin-bottom: 10px;
  margin-top: 20px;

  & > :first-child {
    margin-right: 8px;
  }
`;

const StyledText = styled(Text)`
  @media (max-width: 767px) {
    font-size: 12px;
  }
`;

const AgreementBlue = styled.a`
  color: rgba(72, 114, 242, 1);
  text-decoration: none;
  cursor: pointer;
`;

const Insurances: React.FC<InsurancesProps> = ({ items, isBooking }) => {
  const companies = getProductCompanies(items);
  const availableInsurancesState = useSelector(
    (s: ApplicationState) => s.orders.orderDetail.availableInsurances,
  );

  const isDetail = window.location.pathname.includes('/orders');

  const dispatch = useDispatch();

  return (
    <Wrapper isDetail={isDetail}>
      {/* <div> */}
      {items.filter(
        (x) =>
          x.paymentStatus.code === 'Paid' ||
          x.paymentStatus.code === 'PaidByCredit',
      ).length > 0 && (
        <>
          <Head>
            <Title>Ваше страхование</Title>
            <IconsContainer>
              {companies.map((x) => (
                <img key={x.name} alt={x.name} src={x.logo} />
              ))}
            </IconsContainer>
          </Head>
          <List>
            {items
              .filter(
                (x) =>
                  x.paymentStatus.code === 'Paid' ||
                  x.paymentStatus.code === 'PaidByCredit',
              )
              .map((x, key) => (
                <Position {...x} key={key} />
              ))}
          </List>
          <TotalSum>
            Общая сумма страховок:{' '}
            <strong>{Helper.formatPrice(getTotalSum(items))}</strong>
          </TotalSum>
          <Separator />
        </>
      )}
      {/* </div> */}
      {availableInsurancesState &&
        availableInsurancesState.items.length > 0 && (
          <NotAddedInsurancesOffers>
            <Row>
              <Title>Спокойнее со страховкой</Title>
              <AlphaStrah />
            </Row>
            <Subtitle>
              Отправляясь в путешествие с полисом страхования, Вы можете быть
              уверены в гарантии своей финансовой защиты.
            </Subtitle>
            <AvailableOffersList
              items={availableInsurancesState.items as any}
              onChange={(a, payload) => {
                if (payload.checked) {
                  dispatch(
                    postSessionLogHandler({
                      eventType: UserActionTypes['Click'],
                      actionId:
                        LOGGING_ACTION_IDS.AVIA_BOOKING_SECOND_STEP
                          .REMOVE_INSURANCE_BTN,
                      screen: LOGGING_ACTION_IDS.AVIA_BOOKING_SECOND_STEP.NAME,
                      value: {
                        insurance: a,
                      },
                    }),
                  );
                  dispatch(deleteInsurances([a]));
                } else {
                  dispatch(
                    postSessionLogHandler({
                      eventType: UserActionTypes['Click'],
                      actionId:
                        LOGGING_ACTION_IDS.AVIA_BOOKING_SECOND_STEP
                          .ADD_INSURANCE_BTN,
                      screen: LOGGING_ACTION_IDS.AVIA_BOOKING_SECOND_STEP.NAME,
                      value: {
                        insurance: a,
                      },
                    }),
                  );
                  dispatch(addInsurances([a]));
                }
              }}
              disabled={false}
            />
            {/* <TotalSum>
              Общая сумма страховок:{' '}
              <strong>
                {Helper.formatPrice(
                  availableInsurancesState.items
                    // @ts-ignore
                    .filter((x) => x.checked)
                    // @ts-ignore
                    .reduce((acc, x) => acc + x.totalRate.value, 0)
                )}
              </strong>
            </TotalSum> */}
          </NotAddedInsurancesOffers>
        )}
      <CheckboxWrapper>
        <Checkbox type="squar" checked={true} name="isInsuranceChecked" />{' '}
        <StyledText>
          Я согласен(а) с условиями выбранной страховки и офертой
          страхования&nbsp;
          <AgreementBlue
            href="http://www.alfastrah.ru/docs/PublicOffer_VZR_CLASSIC.pdf"
            target="_blank"
          >
            "Медицинские расходы”
          </AgreementBlue>
          , офертой страхования{' '}
          <AgreementBlue
            href="http://www.alfastrah.ru/docs/Offer_tui_284_own.pdf"
            target="_blank"
          >
            “Полет под защитой”
          </AgreementBlue>
          , офертой страхования{' '}
          <AgreementBlue
            href="http://www.alfastrah.ru/docs/Offer_nsp_247_cov_1.pdf"
            target="_blank"
          >
            “Отмена поездки”
          </AgreementBlue>{' '}
          .
        </StyledText>
      </CheckboxWrapper>
    </Wrapper>
  );
};

export default Insurances;
