import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import {
  TicketList,
  Filter,
  RequestError,
  TicketListSkeleton,
} from '@components/flightsSearch';
import { useParams } from 'react-router-dom';
import { dynamicSearch } from '@modules/simpleSearch';
import { purifySearchState, searchFormDates } from '@modules/main';
import { getNotification } from '@modules/notification/selectors';
import AviaFilteredListEmptyContainer from '@components/flightsSearch/Notifications/AviaFilteredListEmptyContainer';
import { useLocation } from 'react-router';
import withLogging from '@modules/logs/withLogging';
import { LOGGING_ACTION_IDS } from '@modules/logs/constants';
import { selectFlightSearchData } from '@modules/simpleSearch/selectors';
import { FilterSkeleton } from '@components/flightsSearch/TicketList/Skeleton';

const Container = styled.div`
  margin-top: 50px;
  margin-bottom: 20px;
  @media screen and (max-width: 1142px) {
    margin-top: 20px;
  }
`;

const Body = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Content = styled.div`
  display: flex;
  width: 1170px;
  @media screen and (max-width: 1188px) {
    width: 100%;
    padding: 0 40px;
  }
  @media screen and (max-width: 767px) {
    width: 100%;
    padding: 0 20px;
  }
`;

const SkeletonWrapper = styled.div`
  margin-top: 20px;
  width: 100%;
`;

const FlightTickets: React.FC = () => {
  const { flightSearch } = useSelector(selectFlightSearchData);
  const dates = useSelector(searchFormDates);
  const isTwoWay = dates.to !== null;
  const { status, flightsList, filter } = flightSearch;
  const location = useLocation();
  const hasData = flightSearch.data.length > 0;
  const dynamicSearchIsSuccess =
    flightSearch.dynamicSearch.status === 'success';

  const notificationInfo = useSelector(getNotification) as any;

  const ref = React.useRef<HTMLDivElement>(null);

  const dispatch = useDispatch();
  const { params } = useParams<{ params: string }>();

  React.useEffect(() => {
    dispatch(
      dynamicSearch({
        url: params,
        saleChannel: notificationInfo?.salesChannel,
      }),
    );
  }, [params, location.key]);

  React.useEffect(
    () => () => {
      dispatch(purifySearchState());
    },
    [],
  );

  return (
    <Container>
      <Body>
        {dynamicSearchIsSuccess && !hasData && <RequestError type={'avia'} />}
        {((status === 'success' && hasData) ||
          (status === 'loading' && !hasData)) && (
          <Content ref={ref}>
            {(status === 'loading' || status === null) && (
              <SkeletonWrapper>
                <TicketListSkeleton twoWay={isTwoWay} />
              </SkeletonWrapper>
            )}
            {status === 'success' && hasData && (
              <>
                {!dynamicSearchIsSuccess ? (
                  <FilterSkeleton />
                ) : (
                  flightsList.notFilteredItems.length > 0 && <Filter />
                )}
                {flightsList.items.length === 0 && filter?.isFiltered && (
                  <AviaFilteredListEmptyContainer />
                )}
                {flightsList.items.length > 0 && <TicketList />}
              </>
            )}
          </Content>
        )}
      </Body>
    </Container>
  );
};

export default withLogging(FlightTickets, {
  screen: LOGGING_ACTION_IDS.AVIA_FLIGHTS_LIST.NAME,
});
