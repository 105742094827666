import React from 'react';
import styled from 'styled-components';
import { Text, Button } from '@components/ui';
import moment from 'moment';
import { uniq as _uniq } from 'lodash';
import { useOrderPosition } from './useItemProps';
import { Separator } from '@components/common';
import DetailFLightList from './FlightsListDetailed';
import {
  getFlightsInfo,
  AvailableActions,
  cancelOrderPositionRequest,
  getDetailOrder,
  SegmentGroup,
} from '@modules/orders';
import { Api, Helper } from '@utils';
import { ORDER_SERVICE_HOST } from '@modules/constants';
import { getUserState } from '@modules/user';
import { CreateFormPopup } from '@components/common/FormOfAppeal';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import printerIcon from '../../../../../assets/images/order-detail/printer.svg';
import circleIcon from '../../../../../assets/images/order-detail/info-circle.svg';
import cancelIcon from '../../../../../assets/images/order-detail/cancel.svg';
import refundIcon from '../../../../../assets/images/order-detail/refund.svg';
import returnIcon from '../../../../../assets/images/order-detail/change.svg';
import exchangeIcon from '../../../../../assets/images/order-detail/return.svg';
import { ApplicationState } from '@modules/index';
import OrderRouteDetailContainer from '@components/train/RouteDetail/OrderRouteDetailContainer';
import FlightDetailContainerStepTwo from '@components/booking/FlightDetail/Desktop/FlightDetailContainerStepTwo';
import {
  trainRefundRequest,
  cancelOrderPopupToggle,
} from '@modules/trainOrders';
import {
  addAppealRefund,
  getRefundAmount,
  getRefundAmountState,
} from '@modules/appeals';
import { PositionAppealCode } from './types';
import { FLIGHT_TYPES } from '@components/booking/FlightDetail/types';

const BlockWithPadding = styled.div`
  padding: 0 24px;
`;

const Wrapper = styled.div`
  position: relative;
`;

const Top = styled.div`
  display: flex;
  justify-content: space-between;

  & > :last-child {
    text-align: right;
  }

  margin-bottom: 25px;
`;

const Title = styled(Text)`
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
`;

const TextTop = styled.div`
  margin-bottom: 10px;
`;

const Bottom = styled.div`
  display: flex;
  flex-direction: column;

  & > span {
    margin-bottom: 10px;
  }

  & > :last-child {
    margin-bottom: 0;
  }
`;

const GrayText = styled(Text)`
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #3c3c3c;
`;

const BlueText = styled(Text)<{ red: boolean }>`
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: rgba(72, 114, 242, 1);

  ${({ red }) => (red ? 'color: #F64C50;' : 'color: rgba(72, 114, 242, 1);')}
`;

const PriceTitle = styled(GrayText)`
  display: block;
`;

const ActionsList = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -12px;

  & > button {
    margin-right: 12px;
    margin-bottom: 12px;
  }
`;

const ActionButton = styled(Button).attrs({ type: 'outlined' })`
  display: flex;
  align-items: center;

  & > img {
    margin-right: 6px;
  }
`;

const BlueActionButton = styled(Button).attrs({ type: 'primary' })`
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  padding: 7px 16px;

  & > img {
    margin-right: 6px;
  }
`;

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

const ExchangeIconWithAnimation = styled.img`
  animation: rotation 2s infinite linear;
  @keyframes rotation {
    from {
      transform: rotate(-359deg) scaleX(-1);
    }
    to {
      transform: rotate(0deg) scaleX(-1);
    }
  }
`;

const createOrderLink = Api.createFetchUrl(
  ORDER_SERVICE_HOST,
  process.env.REACT_APP_ORDER_API as string,
);

const Head: React.FC = () => {
  const props = useOrderPosition();
  const dispatch = useDispatch();
  const params = useParams<{ id: string }>();
  const history = useHistory();
  const userState = useSelector(getUserState);
  const { salesChannel, orderNumber, id, hasConnectingFlights } =
    useSelector(getDetailOrder);
  const cancelOrderStatus = useSelector(
    (x: ApplicationState) => x.orders.orderDetail.orderPositionCancelStatus,
  );
  const refundAnount = useSelector(getRefundAmountState);

  const [ticketIsLoading, setTicketIsLoading] = React.useState(false);

  const isAccountant = userState.data && userState.data.role === 'Accountant';

  const [appeal, setAppeal] = React.useState<{
    open: boolean;
    subject?: string | null;
  }>({ open: false });

  function fromToStr(arr: SegmentGroup[]) {
    if (!arr || arr.length === 0) return 'нет сегментов';
    if (arr.length === 1) {
      return `${arr[0].departureCity?.name} – ${arr[0].arrivalCity?.name}`;
    }
    return `${arr[0].departureCity?.name} – ${arr[0].arrivalCity?.name} – ${arr[1].arrivalCity?.name}`;
  }

  function fromToStrForTrain(arr: SegmentGroup[]) {
    if (!arr || arr.length === 0) return 'нет сегментов';
    const departureCityNames: string[] = _uniq(
      props.segmentGroups?.map((group) => group.segments[0].departureCity.name),
    );
    const arrivalCityNames: string[] = _uniq(
      props.segmentGroups?.map((group) => group.segments[0].arrivalCity.name),
    );
    if (departureCityNames.length === 1) {
      return `${departureCityNames[0]} – ${arrivalCityNames[0]}`;
    } else {
      return `${departureCityNames[0]} – ${arrivalCityNames[0]} – ${arrivalCityNames[1]}`;
    }
  }

  const fromToNew = props.isTrainOrder
    ? fromToStrForTrain(props.segmentGroups)
    : fromToStr(props.segmentGroups);

  const stillUtc = moment.utc(props.cancellationTime).toDate();
  const localCancellationTime = moment(stillUtc).local();
  const duration = localCancellationTime.diff(moment(), 'minutes');

  const handleAppealClose = () => setAppeal({ open: false });

  const flightListProps = getFlightsInfo(props);
  ``;
  const printLink = createOrderLink(
    process.env.REACT_APP_PRINT_ORDER_E_TICKETS as string,
    { orderId: props.orderId, orderPositionId: props.id, format: 'pdf' },
  );

  const printRefundLink = createOrderLink('/print-refund-blank', {
    orderId: props.orderId,
    orderPositionId: props.id,
    format: 1,
  });

  // если рейс чартерный и статус позиции "отложенная выписка", то открываем только три темы в поддержке
  // TODO: причесать это правило, доступное действие должно приходить с бэка
  const onlyOtherRefundReturnSubjects =
    props.flight_Type === FLIGHT_TYPES.CHARTER &&
    props.status.code === 'DelayedIssue';
  const onlyOtherSubject = (props.availableActions || []).includes(
    'CREATE_APPEAL_WITH_ONLY_OTHER_THEME',
  );

  const downloadFile = async (url: string, orderNumber: number) => {
    setTicketIsLoading(true);
    const response = await fetch(url);
    setTicketIsLoading(false);
    if (response.status !== 200) {
      setTicketIsLoading(false);
      throw new Error('Failed to download file.');
    }
    const blob = await response.blob();
    const blobUrl = URL.createObjectURL(blob);
    const link = document.createElement('a');

    link.href = blobUrl;
    link.download = `Tickets_for_order_N${orderNumber}.pdf`; // set your file name here
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleActionClick: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    const target = e.target as HTMLButtonElement;
    const value = target.dataset.value;
    const actionType = target.dataset.action;

    switch (actionType) {
      case 'payment': {
        history.push(
          `/redirect-payment?orderId=${Number(params.id)}&orderPositionId=${
            props.id
          }`,
        );
        break;
      }
      case 'cancel': {
        dispatch(
          cancelOrderPositionRequest({
            orderId: props.orderId,
            orderPositionId: props.id,
          }),
        );
        break;
      }

      case 'refund': {
        if (props.tariff.gds === 'AEROFLOT') {
          dispatch(addAppealRefund());
          if (refundAnount.error) {
            dispatch(getRefundAmount({ orderPositionId: props.id }));
          }
        }
        setAppeal({ open: true, subject: value });
        break;
      }

      case 'appeal': {
        setAppeal({ open: true, subject: value });
        break;
      }

      case 'train-refund': {
        dispatch(trainRefundRequest({ orderId: orderNumber }));
        break;
      }

      case 'train-cancel-order': {
        dispatch(cancelOrderPopupToggle(true));
        break;
      }

      case 'print':
        downloadFile(printLink, orderNumber);
        break;
      case 'refund-print':
        window.open(printRefundLink, '_blank');
        break;
    }
  };

  const title = React.useMemo(() => {
    if (!props.status) {
      return 'Аннулированная позиция';
    }
    switch (props.status.code) {
      case 'Canceled':
        return 'Отмененная позиция';
      case 'Annulated':
      case 'Refunded':
        return 'Аннулированная позиция';
      default:
        return 'Позиция заказа';
    }
  }, [props.status]);

  return (
    <Wrapper>
      <BlockWithPadding>
        <Top>
          <div>
            <TextTop>
              <Title color={props.disabled ? '#F64C50' : undefined}>
                {title}:{' '}
                <Title color={props.disabled ? '#F64C50' : 'blue'}>
                  {fromToNew}
                </Title>
              </Title>
            </TextTop>
            <Bottom>
              {salesChannel === 'Самотур (GDS тур)' && (
                <GrayText>№ PNR {props.bookingCode}</GrayText>
              )}
              <GrayText>
                Статус {props.isTrainOrder ? 'документа' : 'позиции'}:{' '}
                <BlueText red={props.disabled}>
                  {props.status?.description}
                </BlueText>
              </GrayText>
              <GrayText>
                Статус оплаты:{' '}
                <BlueText red={props.disabled}>
                  {props.paymentStatus?.description}
                </BlueText>
              </GrayText>
              {/* {duration > 0 &&
                (props.availableActions || []).includes('PAYMENT') && (
                  <GrayText>
                    Время для оплаты:{' '}
                    <BlueText red={props.disabled}>
                      {Helper.formatDuration(duration)}
                    </BlueText>
                  </GrayText>
                )} */}
              {props?.airlineOnlineBookingCode?.fullName !== null && (
                <FlexContainer>
                  <GrayText>
                    Номер для онлайн регистрации: &nbsp;
                    {props?.airlineOnlineBookingCode?.bookingCodes?.map(
                      (code: any, index: number) => (
                        <>
                          {code?.airlines?.length === 1 ? (
                            <>
                              <span style={{ color: '#4872F2' }}>
                                {code.code}
                              </span>
                              &nbsp;
                              <span style={{ fontWeight: 'bold' }}>
                                ({code?.airlines[0].name}) &nbsp;
                              </span>
                            </>
                          ) : (
                            <>
                              <span style={{ color: '#4872F2' }}>
                                {code.code}
                              </span>{' '}
                              &nbsp;
                              {code?.airlines?.map(
                                (airline: any, i: number) => (
                                  <>
                                    <span style={{ fontWeight: 'bold' }}>
                                      ({airline.name}) &nbsp;
                                    </span>
                                  </>
                                ),
                              )}
                            </>
                          )}
                        </>
                      ),
                    )}
                  </GrayText>
                </FlexContainer>
              )}
            </Bottom>
          </div>
          <div>
            <PriceTitle>Стоимость:</PriceTitle>
            <Title color={props.disabled ? '#3C3C3C' : 'blue'}>
              <span data-cy={`orderPositionPriceInOrderDetails_${props.id}`}>
                {Helper.formatPrice(props.amount)}
              </span>
            </Title>
          </div>
        </Top>

        {(props.isTrainOrder || (props.availableActions || []).length > 0) && (
          <ActionsList>
            {props.availableActions.includes(AvailableActions.PRINT) && (
              <ActionButton data-action="print" onClick={handleActionClick}>
                {ticketIsLoading ? (
                  <ExchangeIconWithAnimation src={exchangeIcon as any} alt="" />
                ) : (
                  <img src={printerIcon as any} alt="" />
                )}
                Печать билета
              </ActionButton>
            )}
            {!isAccountant && (
              <>
                {props.availableActions.includes(
                  AvailableActions.REFUND_BLANK,
                ) && (
                  <BlueActionButton
                    data-action="refund-print"
                    onClick={handleActionClick}
                  >
                    <img src={refundIcon as any} alt="" />
                    Квитанция на возврат
                  </BlueActionButton>
                )}
                {props.availableActions.includes(AvailableActions.CANCEL) && (
                  <ActionButton
                    isLoading={cancelOrderStatus}
                    data-action="cancel"
                    onClick={handleActionClick}
                  >
                    <img src={cancelIcon as any} alt="" />
                    Отменить позицию
                  </ActionButton>
                )}
                {props.availableActions.includes(
                  AvailableActions.CREATE_APPEAL_WITH_ANY_THEME,
                ) && (
                  <>
                    <ActionButton
                      data-value={PositionAppealCode.FUll_EXCHANGE}
                      data-action="appeal"
                      onClick={handleActionClick}
                    >
                      <img src={exchangeIcon as any} alt="" />
                      Обмен
                    </ActionButton>
                    <ActionButton
                      data-value={PositionAppealCode.FULL_REFUND}
                      data-action="refund"
                      onClick={handleActionClick}
                    >
                      <img src={returnIcon as any} alt="" />
                      Возврат
                    </ActionButton>
                  </>
                )}
                {props.availableActions.includes('REFUND_RAILWAY') && (
                  <ActionButton
                    data-value={PositionAppealCode.FULL_REFUND}
                    data-action="train-refund"
                    onClick={handleActionClick}
                  >
                    <img src={returnIcon as any} alt="" />
                    Возврат
                  </ActionButton>
                )}
                {props.availableActions.includes('RAILWAY_ORDER_CANCEL') && (
                  <ActionButton
                    data-value="TrainCancelOrder"
                    data-action="train-cancel-order"
                    onClick={handleActionClick}
                  >
                    Отмена заказа
                  </ActionButton>
                )}
                {(props.availableActions.includes(
                  AvailableActions.CREATE_APPEAL_WITH_ANY_THEME,
                ) ||
                  props.availableActions.includes(
                    AvailableActions.CREATE_APPEAL_WITH_ONLY_OTHER_THEME,
                  )) && (
                  <ActionButton
                    data-action="appeal"
                    data-value={PositionAppealCode.FUll_EXCHANGE}
                    onClick={handleActionClick}
                  >
                    <img src={circleIcon as any} alt="" />
                    Служба поддержки
                  </ActionButton>
                )}
              </>
            )}
          </ActionsList>
        )}
      </BlockWithPadding>

      {(props.availableActions || []).length > 0 && (
        <Separator style={{ marginBottom: 0 }} />
      )}
      {props.isTrainOrder || props.isBusOrder ? (
        <OrderRouteDetailContainer orderId={props.id} />
      ) : (
        <DetailFLightList
          items={props.segmentGroups}
          hasConnectingFlights={hasConnectingFlights}
        />
      )}

      <CreateFormPopup
        insurances={props.insurances}
        onlyOtherSubject={
          onlyOtherRefundReturnSubjects ? false : onlyOtherSubject
        }
        onlyOtherRefundReturnSubjects={onlyOtherRefundReturnSubjects}
        open={appeal.open}
        onClose={handleAppealClose}
        orderPosition={props}
        defaultSubject={appeal.subject}
      />
    </Wrapper>
  );
};

export default Head;
