import { IFlightSearchState, IFlightSearchIntervals } from './types';

export const FlightSearchState: IFlightSearchState = {
  flightsList: {
    items: [],
    notFilteredItems: [],
  },
  filter: {
    isFiltered: false,
  },
  references: undefined,
  responseId: null,
  errors: null,
  data: [],
  status: null,
  dynamicSearch: {
    status: null,
    gdsCount: 0,
    gdsWithAnswer: 0,
  },
} as any;

export const FlightSearchIntervalsState: IFlightSearchIntervals = {} as any;
