import React from 'react';
import _ from 'lodash';
import { CreateFormData } from '../types';
import { FormikHelpers } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { referencesSelector } from '@modules/references';
import { addAppealRequest, getRefundAmountState } from '@modules/appeals';
import { useProps } from '../useProps';
import { passengersNormalizer, ReducerState } from '../normalizer';
import {
  initialSelect,
  getInitialReason,
  reducer,
} from '../utils/createFormDefault';
import { filterRefundReasons } from '../utils/createFormRefund';
import { PositionAppealCode } from '@app/components/order/Detail/OrderPositions/Item/types';
import { RefundProcessType } from '@components/common/FormOfAppeal/CreateForm/static/Refund';

const useCreateFormDefault = () => {
  const {
    orderPosition,
    defaultSubject,
    onlyOtherSubject,
    insurances,
    onlyOtherRefundReturnSubjects,
  } = useProps();
  const initState = passengersNormalizer(orderPosition, insurances);
  const [files, setFiles] = React.useState<File[]>([]);
  const [state, innerDispatch] = React.useReducer<
    React.Reducer<ReducerState, any>
  >(reducer, initState);
  const refundAmount = useSelector(getRefundAmountState);
  const dispatch = useDispatch();

  const refundReasons = filterRefundReasons(refundAmount.data.involuntry);

  // Filter out AUTO refund reasons if GDS is not AEROFLOT
  const filteredRefundReasons =
    orderPosition.tariff.gds !== 'AEROFLOT'
      ? refundReasons.filter((reason) => reason.code !== RefundProcessType.AUTO)
      : refundReasons;

  const defaultSubjects = useSelector(referencesSelector).appeals.subjects.map(
    (item) => ({
      label: item.description,
      value: item.code,
      code: item.code,
    }),
  );
  const subjects = defaultSubjects.filter((item) =>
    onlyOtherRefundReturnSubjects
      ? item.code === PositionAppealCode.OTHER ||
        item.code === PositionAppealCode.FUll_EXCHANGE
      : onlyOtherSubject
        ? item.code === PositionAppealCode.OTHER
        : item.code !== PositionAppealCode.FULL_REFUND &&
          item.code !== PositionAppealCode.REFUND_ADDITIONAL_SERVICE,
  );
  const initialSubject = onlyOtherSubject
    ? subjects[0]
    : defaultSubjects.find((value) => value.code === defaultSubject) ||
      subjects[0];

  const initialValues: CreateFormData = {
    subject: initialSubject || initialSelect,
    passenger: [],
    message: '',
  };

  const initialReason = getInitialReason();

  const insurancesOptions = _(initState.passengers)
    .map((passenger) =>
      passenger.insurances.map((ins) => ({
        value: `${passenger.value}-${ins.value}`,
        id: ins.value,
        passenger: passenger.value,
        label: `${ins.label} - ${passenger.label}`,
      })),
    )
    .flatten()
    .value();

  const onSubmit = async (
    data: CreateFormData,
    formik: FormikHelpers<CreateFormData>,
  ) => {
    if (data.subject.code === 'RefundInsurance') {
      dispatch(
        addAppealRequest({
          text: data.message,
          passengers: [],
          orderPositionId: orderPosition.id,
          subject: data.subject.value,
          insuranceIds: _(state.passengers)
            .map((passenger) =>
              passenger.insurances
                .filter((value) => value.checked)
                .map((ins) => ins.value),
            )
            .flatten()
            .uniq()
            .value(),
          files,
        }),
      );
    } else {
      if (
        data.subject.code === PositionAppealCode.FULL_REFUND ||
        data.subject.code === PositionAppealCode.FUll_EXCHANGE
      ) {
        if (state.selectedPassengers.length === 0) {
          formik.setFieldError('passengers', 'Ошибка');
          return;
        }
        if (state.selectedSegments.length === 0) {
          formik.setFieldError('segments', 'Ошибка');
          return;
        }
      }
      dispatch(
        addAppealRequest({
          text: data.message,
          passengers: Object.entries(state.passengers)
            .filter(([_, value]) => value.selected)
            .map(([_, value]) => ({
              aviaPassengerId: value.value,
              segments: Object.entries(value.selectedSegments).map(
                ([_, value]) => ({
                  aviaSegmentId: value.value,
                  ancillaryServiceIds: value.selectedAncillaryServices,
                }),
              ),
            })),
          orderPositionId: orderPosition.id,
          subject: data.subject.value,
          insuranceIds: [],
          files,
        }),
      );
    }
  };

  return {
    state,
    innerDispatch,
    onSubmit,
    setFiles,
    insurancesOptions,
    initialValues,
    initialSubject,
    initialReason,
    subjects,
    filteredRefundReasons,
  };
};

export default useCreateFormDefault;
